<template>
	<div>
		<h1>Cache</h1>

		<b-skeleton-wrapper :loading="loading">
			<template #loading>
				<div class="mt-3">
					<b-skeleton-table
						:rows="10"
						:columns="3"
						:table-props="{bordered: true, striped: true, small: true}"
					></b-skeleton-table>
				</div>
			</template>

			<table class="table table-bordered table-striped table-sm mt-3">
				<thead>
					<tr>
						<th>Region</th>
						<th>Cache</th>
						<th>Taille</th>
						<th>Date de dernière mise à jour</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<template v-for="(region, i) in regions">
						<tr v-for="(cache, j) in region.caches" :key="'region-' + i + '-cache-' + j">
							<td>
								{{ region.id }}
							</td>
							<td>
								{{ cache.id }}
							</td>
							<td>
								<span v-if="cache.metadata.size">
									{{ cache.metadata.size }} bytes
								</span>
							</td>
							<td>
								<span v-if="cache.metadata.lastupdated">
									{{ dateFormat(cache.metadata.lastupdated) }}
								</span>
							</td>
							<td>
								<b-dropdown variant="primary" size="sm" text="Actions">
									<b-dropdown-item v-on:click="remove(region, cache)">Supprimer</b-dropdown-item>
									<b-dropdown-item v-on:click="openModal(region, cache)">Propriétés</b-dropdown-item>
								</b-dropdown>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
		</b-skeleton-wrapper>

		<b-modal id="modal" title="Propriétés" size="lg" hide-footer>
			<div class="table-responsive">
				<table class="table table-bordered table-striped table-sm" v-if="selectedRegion && selectedCache">
					<tbody>
						<tr>
							<td>
								CACHE
							</td>
							<td>
								ID
							</td>
							<td>
								{{ selectedCache.id }}
							</td>
						</tr>
						<tr v-for="(value, metadata) in selectedCache.metadata" :key="'cache-metadata-' + metadata">
							<td>
								CACHE
							</td>
							<td>
								{{ metadata }}
							</td>
							<td>
								{{ value }}
							</td>
						</tr>
						<tr>
							<td>
								REGION
							</td>
							<td>
								ID
							</td>
							<td>
								{{ selectedRegion.id }}
							</td>
						</tr>
						<tr v-for="(value, property) in selectedRegion.properties" :key="'region-property-' + property">
							<td>
								REGION
							</td>
							<td>
								{{ property }}
							</td>
							<td>
								{{ value }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</b-modal>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	name: 'Cache',
	data() {
		return {
			loading: true,
			regions: [],
			selectedRegion: null,
			selectedCache: null
		}
	},
	methods: {
    dateFormat(date) {
      return moment(date).format('dddd D MMMM YYYY à HH[h]mm');
    },
		remove(region, cache) {
			this.loading = true;

			this.$emit('addToast', {title: 'Initialisation', message: 'Suppression des données de cache en cours', variant: 'info'});

			this.removeCache(region.id, cache.id).then((response) => {
				if (response.data.message == "success") {
					this.$emit('addToast', {title: 'Succès', message: 'Suppression des données de cache terminé', variant: 'success'});
				} else {
					this.$emit('addToast', {title: 'Erreur', message: response.data.message, variant: 'danger'});
				}

				this.load();
			}).catch(() => {
				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de supprimer les données de cache', variant: 'danger'});

				this.loading = false;
			});
		},
		openModal(region, cache) {
			this.selectedRegion = region;
			this.selectedCache = cache;

			this.$bvModal.show('modal');
		},
		removeCache(region, id) {
			return this.axios.delete(
				'/index.cfm/cache/' + region + '/' + id,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				}
			);
		},
		getCache() {
			return this.axios.get(
				'/index.cfm/cache',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				}
			);
		},
		load() {
			this.loading = true;

			this.$emit('addToast', {title: 'Initialisation', message: 'Chargement des données de cache en cours', variant: 'info'});

			this.getCache().then((response) => {
				this.$emit('addToast', {title: 'Succès', message: 'Chargement des données de cache terminé', variant: 'success'});

				this.regions = response.data.regions;

				this.loading = false;
			}).catch(() => {
				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de charger les données de cache', variant: 'danger'});

				this.loading = false;
			});
		}
	},
	mounted() {
		this.load();
	}					
}
</script>

<style scoped>

</style>
