<template>
  <b-input-group class="datepicker">
    <b-input-group-prepend>
      <b-button variant="dark" @click="prev" :disabled="disabled">
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
    </b-input-group-prepend>

    <b-form-datepicker
      v-model="value"
      :disabled="disabled"
      locale="fr-FR"
      start-weekday="1"
      :hide-header="true"
      :no-highlight-today="true"
      :date-format-options="format"
      label-prev-year="Année précédente"
      label-next-year="Année suivante"
      label-prev-month="Mois précédent"
      label-next-month="Mois suivant"
      label-current-month="Mois en cours"
      label-help=""
    ></b-form-datepicker>

    <b-input-group-append>
      <b-button variant="dark" @click="next" :disabled="disabled">
        <b-icon icon="chevron-right"></b-icon>
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import moment from 'moment';

moment.locale('fr');

export default {
  name: 'DateSlider',
  props: [
    'date',
    'step',
    'format',
    'disabled'
  ],
  data() {
    return {
      value: ''
    };
  },
  watch: {
    date(val) {
      this.value = val.format('YYYY-MM-DD');
    },
    value(val) {
      this.$emit('update', moment(val));
    }
  },
  methods: {
    prev() {
      this.$emit('update', this.date.clone().subtract(this.step, 'd'));
    },
    next() {
      this.$emit('update', this.date.clone().add(this.step, 'd'));
    }
  },
  mounted() {
    if (this.date) {
      this.value = this.date.format('YYYY-MM-DD');
    }
  }
};
</script>

<style scoped>
.datepicker {
  width: 100%;
  text-transform: capitalize;
}
</style>
