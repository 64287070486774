<template>
  <b-toast :title="title" :variant="variant" :visible="true" :solid="true">
    {{ message }}
  </b-toast>
</template>

<script>
export default {
  name: 'Toast',
  props: [
    'title',
    'message',
    'variant'
  ]
};
</script>
