import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginLayout from '../components/layouts/Login'
import AppLayout from '../components/layouts/App'

import Login from '../components/Login'
import Quotas from '../components/Quotas'
import Products from '../components/Products'
import Sells from '../components/Sells'
import Users from '../components/Users'
import Channels from '../components/Channels'
import Bundles from '../components/Bundles'
import Cache from '../components/Cache'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: LoginLayout,
		children: [
			{
				path: '',
				name: 'login',
				component: Login,
				props: (route) => ({ token: route.query.token })
			},
			{
				path: 'logout',
				name: 'logout',
				beforeEnter: (to, from, next) => {
					localStorage.removeItem('token')
					next({ name: 'login' })
				}
			}
		]
	},
	{
		path: '/',
		component: AppLayout,
		beforeEnter: (to, from, next) => {
			if (to.query.token) {
				localStorage.setItem('token', to.query.token)
				next()
			} else if (localStorage.getItem('token')) {
				next()
			} else {
				next({ name: 'login'})
			}
		},
		children: [
			{
				path: 'quotas',
				name: 'quotas',
				component: Quotas
			},
			{
				path: 'products',
				name: 'products',
				component: Products
			},
			{
				path: 'sells',
				name: 'sells',
				component: Sells
			},
			{
				path: 'users',
				name: 'users',
				component: Users
			},
			{
				path: 'channels',
				name: 'channels',
				component: Channels
			},
			{
				path: 'bundles',
				name: 'bundles',
				component: Bundles
			},
			{
				path: 'cache',
				name: 'cache',
				component: Cache
			}
		]
	}
]

const router = new VueRouter({
	routes
})

export default router
