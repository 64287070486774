<template>
  <b-popover
    :target="id"
    placement="rightbottom"
    triggers="hover"
  >
    <div class="row" v-for="product in products" :key="product.id">
      <div class="col text-nowrap overflow-hidden" :title="product.name">
        {{ product.name }}
      </div>
      <div class="col text-nowrap overflow-hidden">
        {{ product.occupation }}/{{ product.quota }}
      </div>
    </div>
  </b-popover>
</template>

<script>
export default {
  name: 'ChannelPopover',
  props: [
    'id',
    'products'
  ]
}
</script>

<style scoped>
.col:nth-child(1) {
  max-width: calc(100% - 84px);
  flex: 0 0 calc(100% - 84px);
}

.col:nth-child(2) {
  max-width: 84px;
  flex: 0 0 84px;
}

.popover {
  min-width: 260px;
  max-width: 350px;
}
</style>
