<template>
	<div class="app-layout d-flex">
		<div>
			<b-sidebar id="sidebar" style="white-space: nowrap" :width="sidebarWidth" :no-close-on-route-change="true" :no-close-on-esc="true" :no-header-close="true" shadow visible>
				<template #header>
						<div @click="toggleSidebar" style="cursor: pointer">
								<img src="/img/favicon-white.png" width="20" style="margin-right: 4px" alt=""> <img src="/img/logo.png" width="200" alt="Kotaweb">
						</div>
						<hr>
				</template>
				<nav>
					<b-nav vertical>
						<li :class="{'active': currentRoute == 'quotas', 'nav-item': true}">
							<router-link :to="{ name: 'quotas'}" class="nav-link text-white">
								<b-icon icon="gear"></b-icon> Quotas
							</router-link>
						</li>
						<li :class="{'active': currentRoute == 'products', 'nav-item': true}">
							<router-link :to="{ name: 'products'}" class="nav-link text-white">
								<b-icon icon="archive"></b-icon> Produits
							</router-link>
						</li>
						<li :class="{'active': currentRoute == 'sells', 'nav-item': true}">
							<router-link :to="{ name: 'sells'}" class="nav-link text-white">
								<b-icon icon="calculator"></b-icon> Contingents
							</router-link>
						</li>
						<li :class="{'active': currentRoute == 'users', 'nav-item': true}">
							<router-link :to="{ name: 'users'}" class="nav-link text-white">
								<b-icon icon="people"></b-icon> Utilisateurs
							</router-link>
						</li>
						<li :class="{'active': currentRoute == 'channels', 'nav-item': true}">
							<router-link :to="{ name: 'channels'}" class="nav-link text-white">
								<b-icon icon="soundwave"></b-icon> Canaux
							</router-link>
						</li>
						<li :class="{'active': currentRoute == 'bundles', 'nav-item': true}">
							<router-link :to="{ name: 'bundles'}" class="nav-link text-white">
								<b-icon icon="diagram2"></b-icon> Bundles
							</router-link>
						</li>
						<li class="nav-item">
							<router-link :to="{ name: 'logout'}" class="nav-link text-white">
								<b-icon icon="power"></b-icon> Déconnexion
							</router-link>
						</li>
					</b-nav>
				</nav>
			</b-sidebar>
		</div>
		<div style="width: 100%; height: 100vh; overflow: auto;">
			<b-container fluid>
				<router-view v-on:addToast="addToast($event)" />
			</b-container>
		</div>
	</div>
</template>

<script>
import { BIcon } from 'bootstrap-vue'

export default {
	name: 'AppLayout',
	components: {
		'b-icon': BIcon
	},
	data() {
		return {
			sidebarIsOpen: localStorage.getItem('sidebar-status') == 'open'
		}
	},
	computed: {
		sidebarWidth() {
			if (this.sidebarIsOpen) return '320px'
			else return '50px'
		},
		currentRoute() {
			return this.$route.name
		}
	},
	methods: {
		toggleSidebar() {
			if (this.sidebarIsOpen) {
				this.sidebarIsOpen = false
				localStorage.setItem('sidebar-status', 'close')
			} else {
				this.sidebarIsOpen = true
				localStorage.setItem('sidebar-status', 'open')
			}
		},
		addToast(toast) {
			this.$emit('addToast', toast);
		}
	}
}
</script>

<style>
.b-sidebar-outer {
	position: relative;
}

.b-sidebar {
	position: relative;
	max-height: 100vh;
	transition: width 0.5s;
	overflow-x: hidden;
	background-color: #2A295C !important;
}

.b-sidebar .b-icon.bi {
	margin-right: 10px;
}

.b-sidebar-body {
	overflow: hidden;
}

.b-sidebar .nav-item:hover, .b-sidebar .nav-item.active {
	background-color: #4A497C;
}

.b-sidebar .nav-item.disabled:hover {
	background-color: transparent;
}

</style>