<template>
    <b-modal scrollable v-model="modal.visible" :title="modal.title" size="xl" no-close-on-backdrop no-close-on-esc hide-footer>
        <div class="row">
            <div class="col">
                <b-form-group :inline="true">
                    <b-form-radio v-model="modal.form.type" value="Presta">Prestation</b-form-radio>
                    <b-form-radio v-model="modal.form.type" value="Extra">Extra</b-form-radio>
                </b-form-group>
            </div>
            <div class="col text-right">
                <b-button type="button" variant="primary" v-on:click="save()">{{ modal.button }}</b-button>
            </div>
        </div>
        <div v-show="modal.form.type == 'Presta'">
            <div class="row">
                <div class="col">
                    <b-form-select v-model="modal.form.metaPrestation" :options="metaPrestationOptions"></b-form-select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    Espaces:
                    <multiselect class="mt-3" v-model="modal.form.areas" :options="areas" track-by="id" label="name" :multiple="true" :close-on-select="false" :searchable="false" :showLabels="false">
                        <template slot="selection" slot-scope="{ values }"><span class="multiselect__single">{{ values.length }} espace(s) selectionné(s)</span></template>
                    </multiselect>
                </div>
            </div>
            <div class="row mt-3" v-if="!modal.form.id">
                <div class="col">
                  Quotas:
                </div>
                <div class="col">
                  <b-form-group :inline="true">
                    <b-form-radio v-model="modal.form.quotaType" value="none">Créer avec un quota à 0</b-form-radio>
                    <b-form-radio v-model="modal.form.quotaType" value="period">Reprendre tous les quotas du service date par date</b-form-radio>
                    <b-form-radio v-model="modal.form.quotaType" value="custom">Personnaliser</b-form-radio>
                  </b-form-group>
                </div>
            </div>
            <div class="row mt-3" v-if="!modal.form.id" v-show="modal.form.quotaType == 'custom'">
                <div class="col">
                  <table class="table table-sm table-products">
                    <tbody>
                      <tr v-for="channel in channels" :key="channel.id">
                        <td class="font-weight-bold">{{ channel.name }}</td>
                        <td>
                          <b-form-input v-model="modal.form.quotas[channel.id]"></b-form-input>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col">
                  <b-form-group label="Appliquer :" v-if="modal.form.quotaDate">
                    <b-form-radio v-model="modal.form.quotaApply" value="day">Journée - {{ modal.form.quotaDate.format('dddd D MMMM') }}</b-form-radio>
                    <b-form-radio v-model="modal.form.quotaApply" value="week">Semaine - du {{ modal.form.quotaDate.clone().startOf('week').format('D MMMM') }} au {{ modal.form.quotaDate.clone().endOf('week').format('D MMMM') }}</b-form-radio>
                    <b-form-radio v-model="modal.form.quotaApply" value="month">Mois - {{ modal.form.quotaDate.format('MMMM YYYY') }}</b-form-radio>
                    <b-form-radio v-model="modal.form.quotaApply" value="year">Année - {{ modal.form.quotaDate.format('YYYY') }}</b-form-radio>
                    <b-form-radio v-model="modal.form.quotaApply" value="period">Période</b-form-radio> du
                    <b-form-datepicker
                      id="period-from"
                      v-model="modal.form.quotaPeriodFrom"
                      locale="fr"
                      :disabled="modal.form.quotaApply != 'period'"
                      label-help=""
                      label-current-month="Mois en cours"
                      label-next-month="Mois suivant"
                      label-next-year="Année suivante"
                      label-prev-month="Mois précédent"
                      label-prev-year="Année précédente"
                      placeholder="Sélectionnez une date"
                    ></b-form-datepicker> au
                    <b-form-datepicker
                      id="period-to"
                      v-model="modal.form.quotaPeriodTo"
                      :min="modal.form.quotaPeriodFrom"
                      locale="fr"
                      :disabled="modal.form.quotaApply != 'period'"
                      label-help=""
                      label-current-month="Mois en cours"
                      label-next-month="Mois suivant"
                      label-next-year="Année suivante"
                      label-prev-month="Mois précédent"
                      label-prev-year="Année précédente"
                      placeholder="Sélectionnez une date"
                    ></b-form-datepicker>
                  </b-form-group>
                  <div class="row mt-3" v-show="modal.form.quotaApply != 'day'">
                    <div class="col">
                      <b-form-checkbox v-model="modal.form.quotaDayOfWeek.monday">Lundi</b-form-checkbox>
                      <b-form-checkbox v-model="modal.form.quotaDayOfWeek.tuesday">Mardi</b-form-checkbox>
                      <b-form-checkbox v-model="modal.form.quotaDayOfWeek.wednesday">Mercredi</b-form-checkbox>
                      <b-form-checkbox v-model="modal.form.quotaDayOfWeek.thursday">Jeudi</b-form-checkbox>
                    </div>
                    <div class="col">
                      <b-form-checkbox v-model="modal.form.quotaDayOfWeek.friday">Vendredi</b-form-checkbox>
                      <b-form-checkbox v-model="modal.form.quotaDayOfWeek.saturday">Samedi</b-form-checkbox>
                      <b-form-checkbox v-model="modal.form.quotaDayOfWeek.sunday">Dimanche</b-form-checkbox>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div class="row" v-show="modal.form.type == 'Extra'">
            <div class="col">
                <b-form-select v-model="modal.form.extra" :options="extraOptions"></b-form-select>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import moment from 'moment';

export default {
    name: 'ProductModal',
    components: {
        'multiselect': Multiselect
    },
    props: [
        'modal',
        'products',
        'metaPrestations',
        'extras',
        'areas',
        'channels'
    ],
    computed: {
        metaPrestationOptions() {
            let options = [];

            for (let i in this.metaPrestations) {
                let metaPrestation = this.metaPrestations[i];
                let found = false;

                for (let j in this.products) {
                    let product = this.products[j];

                    if (metaPrestation.id == product.metaprestation_id) {
                        found = true;
                        break;
                    }
                }

                if (!found || metaPrestation.id == this.modal.form.metaPrestation) {
                    options.push({
                        value: metaPrestation.id,
                        text: metaPrestation.id + ' - ' + metaPrestation.name
                    });
                }
            }

            return options;
        },
        extraOptions() {
            let options = [];

            for (let i in this.extras) {
                let extra = this.extras[i];

                let found = false;

                for (let j in this.products) {
                    let product = this.products[j];

                    if (extra.id == product.extra_id) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    options.push({
                        value: extra.id,
                        text: extra.id + ' - ' + extra.name
                    });
                }
            }

            return options;
        }
    },
    methods: {
        save() {
            let self = this;

            self.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde en cours...'});

            if (self.modal.form.id) {
                self.update().then(function () {
                    self.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
                    self.$emit('reload');
                    self.modal.visible = false;
                }).catch(function () {
                    self.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder', variant: 'danger'});
                });
            } else {
                self.create().then(function () {
                    self.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
                    self.$emit('reload');
                    self.modal.visible = false;
                }).catch(function () {
                    self.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder', variant: 'danger'});
                });
            }
        },
        update() {
            let self = this;

            if (self.modal.form.type == 'Presta') {
                let areas = [];
                for (let i in this.modal.form.areas) {
                    areas.push(this.modal.form.areas[i].id);
                }

                return axios.put(
                    '/index.cfm/products/' + self.modal.form.id,
                    {
                        meta_prestation_id: self.modal.form.metaPrestation,
                        areas: areas
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token'),
                        }
                    }
                );
            } else {
                return axios.put(
                    '/index.cfm/products/' + self.modal.form.id,
                    {
                        extra_id: self.modal.form.extra
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token'),
                        }
                    }
                );
            }
        },
      create() {
        if (this.modal.form.type == 'Presta') {
          let areas = [];

          for (let i in this.modal.form.areas) {
            areas.push(this.modal.form.areas[i].id);
          }

          let quotas = [];
          let range = this.applyRange();

          for (let i in range) {
            for (let j in this.channels) {
              let channel = this.channels[j];

              quotas.push({
                date: range[i].format("YYYY-MM-DD"),
                channel_id: channel.id,
                quota: this.modal.form.quotas[channel.id]
              });
            }
          }

          return axios.post(
            '/index.cfm/products',
            {
              meta_prestation_id: this.modal.form.metaPrestation,
              areas: areas,
              restaurant_id: this.modal.restaurantId,
              quota_type: this.modal.form.quotaType,
              quotas: quotas
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              }
            }
          );
        } else {
            return axios.post(
                '/index.cfm/products',
                {
                    extra_id: this.modal.form.extra,
                    restaurant_id: this.modal.restaurantId
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }
            );
        }
      },
      applyRange() {
        let range = [];

        if (this.modal.form.quotaApply == 'day') {
          range.push(this.modal.form.quotaDate.clone())
          return range;
        }

        let dayOfWeek = [];

        if (this.modal.form.quotaDayOfWeek.monday) dayOfWeek.push(1);
        if (this.modal.form.quotaDayOfWeek.tuesday) dayOfWeek.push(2);
        if (this.modal.form.quotaDayOfWeek.wednesday) dayOfWeek.push(3);
        if (this.modal.form.quotaDayOfWeek.thursday) dayOfWeek.push(4);
        if (this.modal.form.quotaDayOfWeek.friday) dayOfWeek.push(5);
        if (this.modal.form.quotaDayOfWeek.saturday) dayOfWeek.push(6);
        if (this.modal.form.quotaDayOfWeek.sunday) dayOfWeek.push(0);

        let startDate = this.modal.form.quotaDate.clone().startOf(this.modal.form.quotaApply);
        let endDate = this.modal.form.quotaDate.clone().endOf(this.modal.form.quotaApply);

        if (this.modal.form.quotaApply == 'period') {
          startDate = moment(this.modal.form.quotaPeriodFrom);
          endDate = moment(this.modal.form.quotaPeriodTo);
        }

        for (let day = startDate.clone(); day.isSameOrBefore(endDate); day.add(1, 'd')) {
          if (dayOfWeek.length == 0 || dayOfWeek.indexOf(day.day()) != -1) {
            range.push(day.clone());
          }
        }

        return range;
      }
    }
};
</script>

<style scoped>

</style>
