<template>
	<b-modal scrollable :id="id" :title="date.format('dddd D MMMM YYYY')" size="xl" hide-footer>
		<div class="row mt-3">
			<div class="col">
				<div class="mb-3 text-center">
					<b-button variant="outline-secondary" size="sm" class="mx-1" pill v-on:click="openPeriods()">Ouvrir tous les services</b-button>
					<b-button variant="outline-danger" size="sm" class="mx-1" pill v-on:click="closePeriods()">Fermer tous les services</b-button>
				</div>
				<div class="mb-3">
					<b-form-select v-model="form.period" :options="periodOptions"></b-form-select>
				</div>
				<table class="table table-sm table-products">
					<thead>
						<tr>
							<th></th>
							<th
								v-for="channel in channels"
								:key="channel.id"
							>
								{{ channel.name }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr class="period-quota">
							<td><span class="font-weight-bold">{{ periodName }}</span></td>
							<td v-for="channel in channels" :key="channel.id">
								<div class="period-quota-items">
									<div class="period-quota-value">
										<b-form-input v-model="form.periodQuotas[channel.id]"></b-form-input>
									</div>
									<div class="btn-update-products-by-period">
										<b-button
											type="button"
											variant="primary"
											v-b-tooltip.hover :title="'Remplacer ' + channel.quota + ' par la nouvelle valeur'"
											v-on:click="updateListOfProductsByPeriod(channel.id, form.periodQuotas[channel.id])">
											<b-icon-arrow-down style="width: 10px; height: 10px;"></b-icon-arrow-down>
										</b-button>
									</div>
								</div>
							</td>
						</tr>
						<tr
							v-for="product in products"
							:key="product.id"
						>
							<td :title="product.name"><span class="cursor-help">{{ product.name }}</span></td>
							<td
								v-for="channel in channels"
								:key="channel.id"
							>
								<b-form-input v-model="form.productQuotas[channel.id][product.id]" :readonly="product.is_bundle" :title="(product.is_bundle ? 'Les quotas pour les produit bundle ne sont pas modifiable, ils sont calculés à partir de leurs sous-produits' : '')"></b-form-input>
							</td>
						</tr>
					</tbody>
				</table>
				<div>
					<p>Après validation, toutes les valeurs existantes seront écrasées pour le service et les produits affichés.</p>
				</div>
				<div>
					<h5>Commentaires</h5>
					<div>
						<b-form-textarea
							v-model="form.comments"
							rows="3"
							max-rows="8">
						</b-form-textarea>
					</div>
				</div>
				<div class="mt-3 text-center">
					<b-button type="button" variant="primary" :disabled="!isValidForm" v-on:click="save()">VALIDER</b-button>
				</div>
			</div>
			<div class="col">
				<b-form-group label="Appliquer :">
					<b-form-radio v-model="form.apply" value="day">Journée - {{ date.format('dddd D MMMM') }}</b-form-radio>
					<b-form-radio v-model="form.apply" value="week">Semaine - du {{ date.clone().startOf('week').format('D MMMM') }} au {{ date.clone().endOf('week').format('D MMMM') }}</b-form-radio>
					<b-form-radio v-model="form.apply" value="month">Mois - {{ date.format('MMMM YYYY') }}</b-form-radio>
					<b-form-radio v-model="form.apply" value="year">Année - {{ date.format('YYYY') }}</b-form-radio>
					<b-form-radio v-model="form.apply" value="period">Période</b-form-radio> du
					<b-form-datepicker
						id="period-from"
						v-model="form.periodFrom"
						locale="fr"
						:disabled="disabledPeriodDate"
						label-help=""
						label-current-month="Mois en cours"
						label-next-month="Mois suivant"
						label-next-year="Année suivante"
						label-prev-month="Mois précédent"
						label-prev-year="Année précédente"
						placeholder="Sélectionnez une date"
					></b-form-datepicker> au
					<b-form-datepicker
						id="period-to"
						v-model="form.periodTo"
						:min="form.periodFrom"
						locale="fr"
						:disabled="disabledPeriodDate"
						label-help=""
						label-current-month="Mois en cours"
						label-next-month="Mois suivant"
						label-next-year="Année suivante"
						label-prev-month="Mois précédent"
						label-prev-year="Année précédente"
						placeholder="Sélectionnez une date"
					></b-form-datepicker>
				</b-form-group>
				<div class="row mb-3" v-show="displayDaysOfWeek">
					<div class="col">
						<b-form-checkbox v-model="form.dayOfWeek.monday">Lundi</b-form-checkbox>
						<b-form-checkbox v-model="form.dayOfWeek.tuesday">Mardi</b-form-checkbox>
						<b-form-checkbox v-model="form.dayOfWeek.wednesday">Mercredi</b-form-checkbox>
						<b-form-checkbox v-model="form.dayOfWeek.thursday">Jeudi</b-form-checkbox>
					</div>
					<div class="col">
						<b-form-checkbox v-model="form.dayOfWeek.friday">Vendredi</b-form-checkbox>
						<b-form-checkbox v-model="form.dayOfWeek.saturday">Samedi</b-form-checkbox>
						<b-form-checkbox v-model="form.dayOfWeek.sunday">Dimanche</b-form-checkbox>
					</div>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

moment.locale('fr');

export default {
	name: 'DayModal',
	props: [
		'id',
		'date',
		'periods',
		'restaurant'
	],
	data() {
		return {
			form: {
				periodQuotas: {},
				productQuotas: {},
				apply: 'day',
				dayOfWeek: {
					monday: false,
					tuesday: false,
					wednesday: false,
					thursday: false,
					friday: false,
					saturday: false,
					sunday: false
				},
				period: null,
				comments: ''
			},
			error: {
				none: 0,
				quotaEmpty: 1,
				quotaIsNaN: 2,
				badDatas: 3
			}
		};
	},
	computed: {
		period() {
			for (let i in this.periods) {
				let period = this.periods[i];

				if (period.id == this.form.period) {
					return period;
				}
			}

			return null;
		},
		periodName() {
			if (this.period && this.period.name) {
				return this.period.name;
			}

			return "";
		},
		channels() {
			if (this.period) {
				return this.period.channels;
			}

			return {};
		},
		products() {
			if (this.period) {
				for (let i in this.channels) {
					return this.channels[i].products;
				}
			}

			return {};
		},
		applyRange() {
			let range = [];
			if (this.form.apply == 'day') {
				range.push(this.date.clone())
				return range;
			}

			let dayOfWeek = [];

			if (this.form.dayOfWeek.monday) dayOfWeek.push(1);
			if (this.form.dayOfWeek.tuesday) dayOfWeek.push(2);
			if (this.form.dayOfWeek.wednesday) dayOfWeek.push(3);
			if (this.form.dayOfWeek.thursday) dayOfWeek.push(4);
			if (this.form.dayOfWeek.friday) dayOfWeek.push(5);
			if (this.form.dayOfWeek.saturday) dayOfWeek.push(6);
			if (this.form.dayOfWeek.sunday) dayOfWeek.push(0);

			let startDate = this.date.clone().startOf(this.form.apply);
			let endDate = this.date.clone().endOf(this.form.apply);

			if (this.form.apply == 'period') {
				startDate = moment(this.form.periodFrom);
				endDate = moment(this.form.periodTo);
			}

			for (let day = startDate.clone(); day.isSameOrBefore(endDate); day.add(1, 'd')) {
				if (dayOfWeek.length == 0 || dayOfWeek.indexOf(day.day()) != -1) {
					range.push(day.clone());
				}
			}

			return range;
		},
		displayDaysOfWeek() {
			if (this.form.apply != 'day') {
				return true;
			}

			return false;
		},
		disabledPeriodDate() {
			if (this.form.apply != 'period') {
				return true;
			}

			return false;
		},
		isValidForm() {
			if (!this.date || !this.form.period || !this.form.apply) {
				return false;
			}

			if (this.displayDaysOfWeek) {
				const anyDayOfWeek = Object.keys(this.form.dayOfWeek).some(day => this.form.dayOfWeek[day] == true);
				if (!anyDayOfWeek) {
					return false;
				}
			}

			const isValidRangePeriod = this.form.periodFrom && this.form.periodTo;
			if (this.form.apply == 'period' && !isValidRangePeriod) {
				return false;
			}

			return true;
		},
		periodOptions() {
			if (this.periods) {
				return Object.values(this.periods)
					.sort((p1, p2) => {
						const sortAscDate = new Date(p1.time_from) - new Date(p2.time_from);
						const sortDescId = p2.id - p1.id;
						return sortAscDate || sortDescId;
					})
					.map(p => {
						return {
							value: p.id,
							text: p.name
						};
					});
			}
			return [];
		},
	},
	watch: {
		periods(periods) {
			for (let period in periods) {
				this.form.period = period.id;
				break;
			}
		},
		'form.period': function() {
			this.load();
		},
		'form.apply': function() {
			Object.keys(this.form.dayOfWeek).forEach(day => {
						this.form.dayOfWeek[day] = false;
			})
		}
	},
	methods: {
		load() {
			for (let i in this.channels) {
				let channel = this.channels[i];

				this.form.periodQuotas[channel.id] = channel.quota;
				this.form.productQuotas[channel.id] = {};

				for (let j in channel.products) {
					let product = channel.products[j];

					this.form.productQuotas[channel.id][product.id] = product.rawQuota;
				}
			}

			this.form.comments = this.period.comments;
		},
		savePeriodQuotas() {
			let periodQuotas = [];
			let range = this.applyRange;
			let quota = "";
			for (let i in range) {
				for (let channelId in this.form.periodQuotas) {
					quota = this.form.periodQuotas[channelId];
					periodQuotas.push({
						"date": range[i].format("YYYY-MM-DD"),
						"period_id": Number(this.form.period),
						"channel_id": Number(channelId),
						"quota": Number(quota)
					});
				}
			}

			return this.axios.put(
				'/index.cfm/periods/quotas',
				periodQuotas,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					}
				}
			);
		},
		saveProductQuotas() {
			let productQuotas = [];
			let range = this.applyRange;
			let quota = "";
			for (let i in range) {
				for (let channelId in this.form.productQuotas) {
					for (let productId in this.form.productQuotas[channelId]) {
						quota = this.form.productQuotas[channelId][productId];
						productQuotas.push({
							"date": range[i].format("YYYY-MM-DD"),
							"period_id": Number(this.form.period),
							"channel_id": Number(channelId),
							"product_id": Number(productId),
							"quota": Number(quota)
						});
					}
				}
			}

			return this.axios.put(
				'/index.cfm/products/quotas',
				productQuotas,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					}
				}
			);
		},
		saveComments() {
			const periodId = Number(this.period.id);
			const dateDay = this.date.format("YYYY-MM-DD");
			const comments = this.form.comments.trim();

			return this.axios.put(
				'/index.cfm/periods/' + periodId + '/comments',
				{
				"date": dateDay,
				"comments": comments
			},
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					}
				}
			);
		},
		save() {
			const isValidPeriod = this.isValidPeriodQuota();
			const isvalidProduct = this.isValidProductQuota();
			if (isValidPeriod === this.error.badDatas || isvalidProduct === this.error.badDatas) {
				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder', variant: 'danger'});
				return;
			}

			if (isValidPeriod === this.error.quotaEmpty || isvalidProduct === this.error.quotaEmpty) {
				this.$emit('addToast', {title: 'Erreur', message: 'Veuillez remplir tous les champs vides', variant: 'danger'});
				return;
			}

			if (isValidPeriod === this.error.quotaIsNaN || isvalidProduct === this.error.quotaIsNaN) {
				this.$emit('addToast', {title: 'Erreur', message: 'Veuillez remplir tous les champs avec des nombres ou des chiffres', variant: 'danger'});
				return;
			}

			this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde en cours...'});

			axios.all([
				this.savePeriodQuotas(),
				this.saveProductQuotas(),
				this.saveComments()
			]).then((responses) => {
				if (responses[0].data.message == 'success' && responses[1].data.message == 'success' && responses[2].data.message == 'success') {
					this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
				} else {
					this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder', variant: 'danger'});
				}

				this.$emit('reload');
			}).catch(() => {
				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder', variant: 'danger'});

				this.$emit('reload');
			});
		},
		openPeriods() {
			this.$emit('addToast', {title: 'Chargement', message: 'Ouverture des services...'});

			this.axios.put(
				'/index.cfm/restaurants/' + this.restaurant + '/periods/open',
				{
					date: this.date.format('YYYY-MM-DD')
				},
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					}
				}
			).then((response) => {
				if (response.data.message == 'success') {
					this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
				} else {
					this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder', variant: 'danger'});
				}

				this.$emit('reload');
			}).catch(() => {
				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder', variant: 'danger'});
				this.$emit('reload');
			});
		},
		closePeriods() {
			this.$emit('addToast', {title: 'Chargement', message: 'Fermeture des services...'});

			this.axios.put(
				'/index.cfm/restaurants/' + this.restaurant + '/periods/close',
				{
					date: this.date.format('YYYY-MM-DD')
				},
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					}
				}
			).then((response) => {
				if (response.data.message == 'success') {
					this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
				} else {
					this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder', variant: 'danger'});
				}

				this.$emit('reload');
			}).catch(() => {
				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder', variant: 'danger'});
				this.$emit('reload');
			});
		},
		isValidNumber(value) {
			return value !== "" && !isNaN(Number(value));
		},
		isValidDate(value) {
			return moment.isDate(new Date(value));
		},
		isValidProductQuota() {
			let quota = ""
			for (const channelId in this.form.productQuotas) {
				for (const productId in this.form.productQuotas[channelId]) {
					quota = this.form.productQuotas[channelId][productId];
					if (quota === "" ) {
						return this.error.quotaEmpty;
					}

					if (!this.isValidNumber(quota)) {
						return this.error.quotaIsNaN;
					}

					if (!this.isValidNumber(this.form.period) || Number(this.form.period) === 0 || !this.isValidNumber(channelId) || Number(channelId) === 0) {
						return this.error.badDatas;
					}
				}
			}

			return this.error.none;
		},
		isValidPeriodQuota() {
			let quota = "";
			for (let channelId in this.form.periodQuotas) {
				quota = this.form.periodQuotas[channelId];
				if (quota === "" ) {
					return this.error.quotaEmpty;
				}

				if (!this.isValidNumber(quota)) {
					return this.error.quotaIsNaN;
				}

				if (!this.isValidNumber(this.form.period) || Number(this.form.period) === 0 || !this.isValidNumber(channelId) || Number(channelId) === 0) {
					return this.error.badDatas;
				}
			}

			return this.error.none;
		},
		updateListOfProductsByPeriod(channelId, periodQuota) {
			if (!this.isValidNumber(channelId) || !this.isValidNumber(periodQuota)) {
				return;
			}

			let channel = this.channels[channelId];

			for (let j in channel.products) {
				let product = channel.products[j];
				if (channel.quota === product.quota) {
					this.form.productQuotas[channel.id][product.id] = Number(periodQuota);
				}
			}

			this.$forceUpdate();
		},
	},
	mounted() {
		for (let i in this.periods) {
			this.form.period = this.periods[i].id;
			break;
		}
	}
}
</script>

<style scoped>
.modal-title {
	text-align: center;
	text-transform: capitalize;
}

.table-products {
	table-layout: fixed;
}

.table-products thead th {
	text-align: center;
	border: 0;
	width: 100px;
}

.table-products td {
	border: 0;
}

.table-products thead th:first-child {
	width: auto;
}

.table-products td:first-child {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.table-products input {
	text-align: right;
}

.period-quota {
	background-color: #eeeeee;
}

.period-quota-items {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.btn-update-products-by-period,
.btn-update-products-by-period button {
	display: flex;
	align-items: center;
}

.btn-update-products-by-period {
	margin-left: 2px;
}

.btn-update-products-by-period button {
	padding: 5px;
	width: 20px;
	height: 20px;
	background-color: #4947a0;
	border: #4947a0;
}
</style>
