<template>
  <div class="dayOfWeek">
    <div class="row">
      <div class="col bg-dark text-white py-2">
        <div class="row dayOfWeek-row">
          <div class="col font-weight-bold text-capitalize">
            {{ title }}
          </div>
          <div class="col text-right" style="cursor: pointer;">
            <b-icon icon="pencil-square" :variant="isAnyComments() ? 'danger' : ''" v-b-modal="getDayModalId()"></b-icon>
          </div>
        </div>
        <div class="row">
            <div class="col font-weight-bold text-capitalize">
              {{ subtitle }}
            </div>
        </div>
        <div class="row dayOfWeek-row mt-4">
          <div class="col" title="Pourcentage de pax confirmés sur les quotas de tous les services et tous les prestataires pour la journée">
            Remplissage
          </div>
          <div class="col text-right">
            {{ filling }}%
          </div>
        </div>
        <div class="row dayOfWeek-row" v-for="product in availabilitiesProducts" :key="product.id" title="Somme des extras confirmés pour la journée">
          <div class="col">
            {{ product.name }}
          </div>
          <div class="col text-right">
            {{ product.occupation }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="period in availabilitiesPeriods"
      :key="period.id"
      :class="{'row period mt-2 py-1': true, 'closed': period.guestonline && period.guestonline.closed}"
      v-show="isPeriodSelected(period.id)"
      v-b-modal="getPeriodModalId(period.id)"
    >
      <div class="col">
        <div class="row period-row font-weight-bold">
          <div class="col" :title="period.name">
            {{ period.name }}
          </div>
          <div class="col text-right">
            {{ period.occupation }}/{{ period.quota }}
          </div>
          <div class="col text-right">
            <b-icon icon="info-circle-fill" :variant="period.comments ? 'danger' : 'dark'" :id="getPeriodPopoverId(period.id)"></b-icon>
            <period-popover :id="getPeriodPopoverId(period.id)" :areas="period.areas"></period-popover>
          </div>
        </div>
        <div class="row channel" v-for="channel in period.channels" :key="channel.id">
          <div class="col ps-3" :title="channel.name">
            {{ channel.name }}
          </div>
          <div class="col text-right">
            {{ channel.occupation }}/{{ channel.quota }}
          </div>
          <div class="col text-right">
            <b-icon icon="info-circle-fill" :id="getChannelPopoverId(period.id, channel.id)"></b-icon>
            <channel-popover :id="getChannelPopoverId(period.id, channel.id)" :products="channel.products"></channel-popover>
          </div>
        </div>
        <period-modal :id="getPeriodModalId(period.id)" :date="date" :initialPeriod="period" :periods="availabilitiesPeriods" v-on:reload="reloadCalendar()" v-on:addToast="addToast($event)"></period-modal>
      </div>
    </div>
    <day-modal :id="getDayModalId()" :date="date" :periods="availabilitiesPeriods" :restaurant="restaurant" v-on:reload="reloadCalendar()" v-on:addToast="addToast($event)"></day-modal>
  </div>
</template>

<script>
import DayModal from './DayModal';
import PeriodModal from './PeriodModal';
import PeriodPopover from './PeriodPopover';
import ChannelPopover from './ChannelPopover';

export default {
  name: 'DayOfWeek',
  components: {
    'day-modal': DayModal,
    'period-modal': PeriodModal,
    'period-popover': PeriodPopover,
    'channel-popover': ChannelPopover
  },
  props: [
    'date',
    'selectedPeriods',
    'restaurant',
    'availabilities'
  ],
  computed: {
    availabilitiesPeriods() {
      if (this.availabilities && this.availabilities.periods) {
        return Object.values(this.availabilities.periods)
          .sort((p1, p2) => {
            const sortAscDate = new Date(p1.time_from) - new Date(p2.time_from);
            const sortDescId = p2.id - p1.id;
            return sortAscDate || sortDescId;
          });
      }

      return {};
    },
    title() {
      if (this.date) {
        return this.date.format('dddd D');
      }

      return '';
    },
    subtitle() {
      if (this.date) {
        return this.date.format('MMMM YYYY');
      }

      return '';
    },
    filling() {
      if (this.availabilities && this.availabilities.quota > 0) {
        return Math.round((this.availabilities.occupation / this.availabilities.quota) * 100);
      }

      return 0;
    },
    availabilitiesProducts() {
      if (this.availabilities && this.availabilities.products) {
        return this.availabilities.products;
      }

      return {};
    }
  },
  methods: {
    addToast(toast) {
      this.$emit('addToast', toast);
    },
    getPeriodPopoverId(periodId) {
      if (this.date) {
        return this.date.format('YYYY-MM-DD') + '-' + periodId;
      }

      return '';
    },
    getChannelPopoverId(periodId, channelId) {
      if (this.date) {
        return this.date.format('YYYY-MM-DD') + '-' + periodId + '-' + channelId;
      }

      return '';
    },
    getDayModalId() {
      if (this.date) {
        return this.date.format('YYYY-MM-DD');
      }

      return '';
    },
    getPeriodModalId(periodId) {
      if (this.date) {
        return this.date.format('YYYY-MM-DD') + '-' + periodId;
      }

      return '';
    },
    isPeriodSelected(id) {
      for(let i in this.selectedPeriods) {
        if (this.selectedPeriods[i].id == id) {
          return true;
        }
      }

      return false;
    },
    reloadCalendar() {
      this.$emit('load');
    },
    isAnyComments() {
      return this.availabilitiesPeriods.some(period => period.comments);
    }
  }
}
</script>

<style scoped>
.calendar .row {
  margin-right: -5px;
  margin-left: -5px;
}

.calendar .col {
  padding-right: 5px;
  padding-left: 5px;
  white-space: nowrap;
  overflow: hidden;
}

.dayOfWeek-row svg {
  width: 16px;
  height: 16px;
}

.dayOfWeek-row > .col:nth-child(1) {
  max-width: calc(100% - 42px);
  flex: 0 0 calc(100% - 42px);
}

.dayOfWeek-row > .col:nth-child(2) {
  max-width: 42px;
  flex: 0 0 42px;
}

.period {
  border: 1px solid transparent;
  cursor: pointer;
}

.period:hover {
  background-color: #f8f0f5;
  border-color: #c6aebd;
}

.period.closed {
  background-color: #ff9f9f;
  border-color: #df7f7f;
}

.period.closed:hover {
  background-color: #df7f7f;
  border-color: #ef5f5f;
}

.period-row > .col:nth-child(1),
.channel > .col:nth-child(1) {
  max-width: calc(100% - 81px);
  flex: 0 0 calc(100% - 81px);
}

.period-row > .col:nth-child(2),
.channel > .col:nth-child(2) {
  max-width: 58px;
  flex: 0 0 58px;
}

.period-row > .col:nth-child(3),
.channel > .col:nth-child(3) {
  max-width: 23px;
  flex: 0 0 23px;
}

@media (max-width: 1240px) {
  .calendar .row {
    margin-right: -2px;
    margin-left: -2px;
  }

  .calendar .col {
    padding-right: 2px;
    padding-left: 2px;
    flex-basis: auto;
  }

  .dayOfWeek-row svg {
    width: 10px;
    height: 10px;
  }

  .period svg {
    width: 10px;
    height: 10px;
  }

  .dayOfWeek-row > .col:nth-child(1) {
    max-width: calc(100% - 31px);
    flex: 0 0 calc(100% - 31px);
  }

  .dayOfWeek-row > .col:nth-child(2) {
    max-width: 31px;
    flex: 0 0 31px;
  }

  .period-row > .col:nth-child(1),
  .channel > .col:nth-child(1) {
    max-width: calc(100% - 59px);
    flex: 0 0 calc(100% - 59px);
  }

  .period-row > .col:nth-child(2),
  .channel > .col:nth-child(2) {
    max-width: 45px;
    flex: 0 0 45px;
  }

  .period-row > .col:nth-child(3),
  .channel > .col:nth-child(3) {
    max-width: 14px;
    flex: 0 0 14px;
  }
}

@media (max-width: 990px) {
  .calendar .row {
    margin-right: -15px;
    margin-left: -15px;
  }

  .calendar .col {
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: auto;
  }

  .dayOfWeek-row svg {
    width: 20px;
    height: 20px;
  }

  .period svg {
    width: 20px;
    height: 20px;
  }

  .dayOfWeek-row > .col:nth-child(1) {
    max-width: calc(100% - 70px);
    flex: 0 0 calc(100% - 70px);
  }

  .dayOfWeek-row > .col:nth-child(2) {
    max-width: 70px;
    flex: 0 0 70px;
  }

  .period-row > .col:nth-child(1),
  .channel > .col:nth-child(1) {
    max-width: calc(100% - 142px);
    flex: 0 0 calc(100% - 142px);
  }

  .period-row > .col:nth-child(2),
  .channel > .col:nth-child(2) {
    max-width: 92px;
    flex: 0 0 92px;
  }

  .period-row > .col:nth-child(3),
  .channel > .col:nth-child(3) {
    max-width: 50px;
    flex: 0 0 50px;
  }
}
</style>
