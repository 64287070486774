<template>
	<div>
		<b-skeleton-wrapper :loading="formLoading">
			<template #loading>
				<b-card>
					<b-row class="mb-3">
						<b-col cols="3">
							<b-skeleton></b-skeleton>
						</b-col>
						<b-col cols="9">
							<b-skeleton type="input"></b-skeleton>
						</b-col>
					</b-row>
					<b-row class="mb-3">
						<b-col cols="3">
							<b-skeleton></b-skeleton>
						</b-col>
						<b-col cols="4">
							<b-skeleton type="input"></b-skeleton>
						</b-col>
						<b-col cols="1">
							<b-skeleton></b-skeleton>
						</b-col>
						<b-col cols="4">
							<b-skeleton type="input"></b-skeleton>
						</b-col>
					</b-row>
					<b-row class="mb-3">
						<b-col cols="3">
							<b-skeleton></b-skeleton>
						</b-col>
						<b-col cols="1">
							<b-skeleton type="input"></b-skeleton>
						</b-col>
						<b-col cols="2">
							<b-skeleton></b-skeleton>
						</b-col>
						<b-col cols="1">
							<b-skeleton type="input"></b-skeleton>
						</b-col>
						<b-col cols="2">
							<b-skeleton></b-skeleton>
						</b-col>
						<b-col cols="1">
							<b-skeleton type="input"></b-skeleton>
						</b-col>
						<b-col cols="2">
							<b-skeleton></b-skeleton>
						</b-col>
					</b-row>
					<b-row class="mb-3">
						<b-col cols="3">
							<b-skeleton></b-skeleton>
						</b-col>
						<b-col cols="1">
							<b-skeleton type="input"></b-skeleton>
						</b-col>
						<b-col cols="2">
							<b-skeleton></b-skeleton>
						</b-col>
						<b-col cols="1">
							<b-skeleton type="input"></b-skeleton>
						</b-col>
						<b-col cols="2">
							<b-skeleton></b-skeleton>
						</b-col>
						<b-col cols="1">
							<b-skeleton type="input"></b-skeleton>
						</b-col>
						<b-col cols="2">
							<b-skeleton></b-skeleton>
						</b-col>
					</b-row>
					<b-row class="mb-3">
						<b-col cols="3">
							<b-skeleton></b-skeleton>
						</b-col>
						<b-col cols="1">
							<b-skeleton type="input"></b-skeleton>
						</b-col>
						<b-col cols="2">
							<b-skeleton></b-skeleton>
						</b-col>
						<b-col cols="1">
							<b-skeleton type="input"></b-skeleton>
						</b-col>
						<b-col cols="2">
							<b-skeleton></b-skeleton>
						</b-col>
						<b-col cols="1">
							<b-skeleton type="input"></b-skeleton>
						</b-col>
						<b-col cols="2">
							<b-skeleton></b-skeleton>
						</b-col>
					</b-row>
					<b-skeleton type="button"></b-skeleton>
				</b-card>
			</template>

			<b-card class="card-form mt-3">
				<b-form @submit="onSubmit">
					<b-form-group
						label-cols="3"
						content-cols="9"
						label="Restaurant"
						label-for="restaurant"
					>
						<b-form-select
							id="restaurant"
							v-model="form.restaurant"
							size="sm"
							:options="restaurantOptions"
						></b-form-select>
					</b-form-group>

					<b-row class="form-row form-group">
						<b-col cols="3">
							De
						</b-col>
						<b-col cols="4">
							<b-form-datepicker
								id="date-from"
								v-model="form.dateFrom"
								locale="fr"
								label-help=""
								size="sm"
								label-current-month="Mois en cours"
								label-next-month="Mois suivant"
								label-next-year="Année suivante"
								label-prev-month="Mois précédent"
								label-prev-year="Année précédente"
							></b-form-datepicker>
						</b-col>
						<b-col cols="1" class="text-center">
							à
						</b-col>
						<b-col cols="4">
							<b-form-datepicker
								id="date-to"
								v-model="form.dateTo"
								:min="form.dateFrom"
								locale="fr"
								size="sm"
								label-help=""
								label-current-month="Mois en cours"
								label-next-month="Mois suivant"
								label-next-year="Année suivante"
								label-prev-month="Mois précédent"
								label-prev-year="Année précédente"
							></b-form-datepicker>
						</b-col>
					</b-row>

					<b-form-group
						label-cols="3"
						content-cols="9"
						label="Services"
					>
						<b-form-checkbox-group
							v-model="form.periods"
							:options="periodOptions"
						></b-form-checkbox-group>
					</b-form-group>

					<b-form-group
						label-cols="3"
						content-cols="9"
						label="Canaux"
					>
						<b-form-checkbox-group
							v-model="form.channels"
							:options="channelOptions"
						></b-form-checkbox-group>
					</b-form-group>

					<b-form-group
						label-cols="3"
						content-cols="9"
						label="Statuts"
					>
						<b-form-checkbox-group
							v-model="form.statuses"
							:options="statusOptions"
						></b-form-checkbox-group>
					</b-form-group>

					<b-row>
						<b-col cols="3">
							<b-button variant="primary" size="sm" @click="download()">
								<b-icon icon="file-arrow-down"></b-icon> Exporter
							</b-button>
						</b-col>
						<b-col cols="6">
							<div class="alert-template" style="background-color: orange;"></div> >= à 75% du contingent
							<div class="alert-template" style="background-color: red;"></div> >= au contingent
						</b-col>
						<b-col cols="3" class="text-right">
							<b-button type="submit" variant="primary" size="sm">
								<b-icon icon="search"></b-icon> Filtrer
							</b-button>
						</b-col>
					</b-row>
				</b-form>
			</b-card>
		</b-skeleton-wrapper>

		<div class="mt-5">
			<b-skeleton-wrapper :loading="dataLoading">
				<template #loading>
						<b-skeleton-table
							:rows="10"
							:columns="17"
							:table-props="{ bordered: true, striped: true }"
						></b-skeleton-table>
				</template>

				<table class="table table-sm table-data mt-5">
					<thead>
						<tr>
							<th></th>
							<th>
								<b-button variant="primary" size="sm" @click="expand()">
									<span v-if="expandStatus == 'expanded'"><b-icon icon="arrows-collapse"></b-icon> Tout plier</span>
									<span v-else><b-icon icon="arrows-expand"></b-icon> Tout étendre</span>
								</b-button>
							</th>
							<th
								v-for="channel in contingent.channels"
								:key="'channel-' + channel.id + '-title'"
								colspan="3"
								class="bl-bold"
							>
								Canal {{ channel.name }}
							</th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
						<tr>
							<th>Date</th>
							<th>Service</th>
							<template v-for="channel in contingent.channels">
								<th
									:key="'channel-' + channel.id + '-title-quota'"
									class="bl-bold"
									:title="'Quota paramétré dans Kotaweb pour le canal ' + channel.name + '. Pour les prestations, le minimum entre le quota du service et le quota de la prestation est pris en compte.'"
								>
									Contingent
								</th>
								<th
									:key="'channel-' + channel.id + '-title-occupation'"
									:title="'Occupation du canal ' + channel.name + ' calculée à partir des réservations avec un statut parmis ceux filtrés.'"
								>
									Pax vendus
								</th>
								<th
									:key="'channel-' + channel.id + '-title-availability'"
									:title="'Disponibilité du canal ' + channel.name + ' calculée avec la différence entre le quota et l\'occupation.'"
								>
									Pax restants
								</th>
							</template>
							<th>Total contingents</th>
							<th>Total pax vendus</th>
							<th>Total pax restants</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, i) in rows" :key="'row-' + i" v-show="isRowVisible(row.is_period_row, row.parent_row_index)" :class="{'date-even': row.is_date_even}">
							<td>
								<div v-if="row.is_period_row" class="text-capitalize">
									{{ formatDate(row.date) }}
								</div>
							</td>
							<td>
								<b-button
									v-if="row.is_period_row || row.has_bundle_children || row.has_bundle_parents"
									variant="link"
									class="btn-collapse"
									@click="toggleRow(i)"
									:title="row.name"
								>
									<b-icon v-if="row.has_bundle_children" icon="diagram2-fill"></b-icon>
									<b-icon v-else-if="row.has_bundle_parents" icon="diagram2"></b-icon>
									<b-icon v-else icon="triangle-fill" :rotate="isRowExpanded(i) ? 180 : 90" scale="0.5"></b-icon>
									{{ row.name }}
								</b-button>
								<div v-else :title="row.name" :style="{'margin-left': 20 * row.tab + 'px'}">
									{{ row.name }}
								</div>
							</td>
							<template v-for="(channel, j) in row.channels">
								<td
									:key="'row-' + i + '-channel-' + j + '-quota'"
									:class="{'full': channel.is_full, 'busy': channel.is_busy, 'bl-bold': true}"
								>
									{{ channel.quota }}
								</td>
								<td
									:key="'row-' + i + '-channel-' + j + '-occupation'"
									:class="{'full': channel.is_full, 'busy': channel.is_busy}"
								>
									{{ channel.occupation }}
								</td>
								<td
									:key="'row-' + i + '-channel-' + j + '-availability'"
									:class="{'full': channel.is_full, 'busy': channel.is_busy}"
								>
									{{ channel.availability }}
								</td>
							</template>
							<td :class="{'full': row.is_full, 'busy': row.is_busy}">{{ row.quota }}</td>
							<td :class="{'full': row.is_full, 'busy': row.is_busy}">{{ row.occupation }}</td>
							<td :class="{'full': row.is_full, 'busy': row.is_busy}">{{ row.availability }}</td>
						</tr>
						<tr>
							<th colspan="2">Total</th>
							<template v-for="(channel, i) in contingent.channels">
								<td
									:key="'channel-' + i + '-total-quota'"
									:class="{'full': channel.is_full, 'busy': channel.is_busy, 'bl-bold': true}"
								>
									{{ channel.quota }}
								</td>
								<td
									:key="'channel-' + i + '-total-occupation'"
									:class="{'full': channel.is_full, 'busy': channel.is_busy}"
								>
									{{ channel.occupation }}
								</td>
								<td
									:key="'channel-' + i + '-total-availability'"
									:class="{'full': channel.is_full, 'busy': channel.is_busy}"
								>
									{{ channel.availability }}
								</td>
							</template>
							<td :class="{'full': contingent.is_full, 'busy': contingent.is_busy}">{{ contingent.quota }}</td>
							<td :class="{'full': contingent.is_full, 'busy': contingent.is_busy}">{{ contingent.occupation }}</td>
							<td :class="{'full': contingent.is_full, 'busy': contingent.is_busy}">{{ contingent.availability }}</td>
						</tr>
					</tbody>
				</table>
			</b-skeleton-wrapper>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

moment.locale('fr');

export default {
	name: 'Sells',
	data() {
		return {
			formLoading: true,
			dataLoading: true,
			form: {
				restaurant: '',
				dateFrom: '',
				dateTo: '',
				periods: [],
				channels: [],
				statuses: []
			},
			restaurants: [],
			periods: [],
			channels: [],
			statuses: [],
			contingent: {
				dates: [],
				channels: []
			},
			rowsExpanded: []
		}
	},
	computed: {
		restaurantOptions() {
			let options = [];

			this.restaurants.forEach((restaurant) => {
				options.push({
					value: restaurant.id,
					text: restaurant.name
				});
			});

			return options;
		},
		periodOptions() {
			let options = [];

			this.periods.forEach((period) => {
				options.push({
					value: period.id,
					text: period.name
				});
			});

			return options;
		},
		channelOptions() {
			let options = [];

			this.channels.forEach((channel) => {
				options.push({
					value: channel.id,
					text: channel.name
				});
			});


			return options;
		},
		statusOptions() {
			let options = [];

			this.statuses.forEach((status) => {
				options.push({
					value: status.id,
					text: status.name
				});
			});

			return options;
		},
		selectedChannels() {
			let channels = [];

			this.channels.forEach((channel) => {
				if (this.form.channels.indexOf(channel.id) != -1) {
					channels.push(channel);
				}
			});

			return channels;
		},
		expandStatus() {
			if (this.rowsExpanded.length == 0) {
				return 'collapsed';
			}

			return 'expanded';
		},
		rows() {
			let rows = [];
			let isDateEven = true;
			let rowIndex = 0;
			let parentPeriodRowIndex = 0;
			let parentProductRowIndex = 0;

			this.contingent.dates.forEach((date) => {
				date.periods.forEach((period) => {
					rows.push({
						id: period.id,
						is_period_row: true,
						is_product_row: false,
						tab: 0,
						has_bundle_parents: false,
						has_bundle_children: false,
						parent_row_index: null,
						is_date_even: isDateEven,
						date: moment(date.date),
						name: period.name,
						channels: period.channels,
						quota: period.quota,
						occupation: period.occupation,
						availability: period.availability,
						is_full: period.is_full,
						is_busy: period.is_busy
					});

					parentPeriodRowIndex = rowIndex;
					rowIndex++;

					period.products.forEach((product) => {
						rows.push({
							id: product.id,
							is_period_row: false,
							is_product_row: true,
							tab: 0,
							has_bundle_parents: product.bundles.length,
							has_bundle_children: product.bundleChildren.length,
							parent_row_index: parentPeriodRowIndex,
							is_date_even: isDateEven,
							date: moment(date.date),
							name: product.name,
							channels: product.channels,
							quota: product.quota,
							occupation: product.occupation,
							availability: product.availability,
							is_full: product.is_full,
							is_busy: product.is_busy
						});

						parentProductRowIndex = rowIndex;
						rowIndex++;

						if (product.bundles.length) {
							rows.push({
								id: product.id,
								is_period_row: false,
								is_product_row: true,
								tab: 1,
								has_bundle_parents: false,
								has_bundle_children: false,
								parent_row_index: parentProductRowIndex,
								is_date_even: isDateEven,
								date: moment(date.date),
								name: product.name,
								channels: product.rawChannels,
								quota: product.bundles.length ? product.rawQuota : '',
								occupation: product.rawOccupation,
								availability: '',
								is_full: false,
								is_busy: false
							});

							rowIndex++;
						}

						product.bundles.forEach((bundle) => {
							rows.push({
								id: bundle.id,
								is_period_row: false,
								is_product_row: false,
								tab: 2,
								has_bundle_parents: false,
								has_bundle_children: false,
								parent_row_index: parentProductRowIndex,
								is_date_even: isDateEven,
								date: moment(date.date),
								name: bundle.name,
								channels: bundle.channels,
								quota: bundle.quota,
								occupation: bundle.occupation,
								availability: bundle.availability,
								is_full: bundle.is_full,
								is_busy: bundle.is_busy
							});

							rowIndex++;
						});

						product.bundleChildren.forEach((bundleChild) => {
							rows.push({
								id: bundleChild.id,
								is_period_row: false,
								is_product_row: false,
								tab: bundleChild.is_bundle_child_bundle ? 2 : 1,
								has_bundle_parents: false,
								has_bundle_children: false,
								parent_row_index: parentProductRowIndex,
								is_date_even: isDateEven,
								date: moment(date.date),
								name: bundleChild.name,
								channels: bundleChild.channels,
								quota: bundleChild.quota,
								occupation: bundleChild.occupation,
								availability: bundleChild.availability,
								is_full: bundleChild.is_full,
								is_busy: bundleChild.is_busy
							});

							rowIndex++;
						});
					});
				});

				isDateEven = !isDateEven;
			});

			return rows;
		}
	},
	watch: {
		'form.restaurant': function(restaurantId) {
			this.formLoading = true;
			this.dataLoading = true;

			this.getPeriods(restaurantId).then((response) => {
				this.periods = response.data;

				this.form.periods = [];

				this.periods.forEach((period) => {
					this.form.periods.push(period.id);
				});

				this.formLoading = false;

				this.loadData();
			}).catch(() => {
				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de charger les filtres', variant: 'danger'});
			});
		},
		'form.dateFrom': function(dateFrom) {
			if (moment(this.form.dateTo).isBefore(moment(dateFrom))) {
				this.form.dateTo = dateFrom;
			}
		}
	},
	methods: {
		onSubmit(event) {
			event.preventDefault();

			this.loadData();
		},
		getRestaurants() {
			return this.axios.get(
				'/index.cfm/restaurants',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				}
			);
		},
		getPeriods(restaurantId) {
			return this.axios.get(
				'/index.cfm/restaurants/' + restaurantId + '/periods',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				}
			);
		},
		getChannels() {
			return this.axios.get(
				'/index.cfm/channels',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				}
			);
		},
		getStatuses() {
			return this.axios.get(
				'/index.cfm/bookings/statuses',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				}
			);
		},
		getContingent(restaurantId) {
			return this.axios.post(
				'/index.cfm/restaurants/' + restaurantId + '/contingent',
				{
					restaurant_id: this.form.restaurant,
					date_from: this.form.dateFrom,
					date_to: this.form.dateTo,
					periods: this.form.periods,
					channels: this.form.channels,
					statuses: this.form.statuses
				},
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				}
			);
		},
		toggleRow(i) {
			let index = this.rowsExpanded.indexOf(i);

			if (index == -1) {
				this.rowsExpanded.push(i);
			} else {
				this.rowsExpanded.splice(index, 1);
			}

			for (let j in this.rows) {
				j = parseInt(j);
				index = this.rowsExpanded.indexOf(j);

				if (index != -1 && this.rows[j].parent_row_index == i) {
					this.rowsExpanded.splice(index, 1);
				}
			}
		},
		isRowExpanded(index) {
			return this.rowsExpanded.indexOf(index) != -1;
		},
		isRowVisible(isPeriodRow, parentRowIndex) {
			return isPeriodRow || this.isRowExpanded(parentRowIndex);
		},
		expand() {
			if (this.expandStatus == 'expanded') {
				this.rowsExpanded = [];
			} else {
				this.rows.forEach((data, i) => {
					if (data.is_period_row && this.rowsExpanded.indexOf(i) == -1) {
						this.rowsExpanded.push(i);
					}
				});
			}
		},
		formatDate(date) {
			return moment(date).format('dddd D MMMM YYYY');
		},
		loadForm() {
			this.formLoading = true;

			axios.all([
				this.getRestaurants(),
				this.getChannels(),
				this.getStatuses()
			]).then((responses) => {
				this.restaurants = responses[0].data;
				this.channels = responses[1].data;
				this.statuses = responses[2].data;

				this.form.restaurant = this.restaurants[0].id;

				this.channels.forEach((channel) => {
					this.form.channels.push(channel.id);
				});

				this.statuses.forEach((status) => {
					this.form.statuses.push(status.id);
				});
			}).catch(() => {
				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de charger les filtres', variant: 'danger'});
			});
		},
		loadData() {
			this.dataLoading = true;

			this.getContingent(this.form.restaurant).then((response) => {
				this.contingent = response.data;

				this.dataLoading = false;
			}).catch(() => {
				this.dataLoading = false;

				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de charger les données', variant: 'danger'});
			});
		},
		download() {
			this.$emit('addToast', {title: 'Chargement', message: 'Préparation du téléchargement...'});

			let params = {
				token: localStorage.getItem('token'),
				restaurant_id: this.form.restaurant,
				date_from: this.form.dateFrom,
				date_to: this.form.dateTo,
				periods: this.form.periods.join(','),
				channels: this.form.channels.join(','),
				statuses: this.form.statuses.join(',')
			};

			const queryString = Object.keys(params)
				.map(key => `${key}=${params[key]}`)
				.join('&');

			window.location.href = '/index.cfm/restaurants/' + this.form.restaurant + '/contingent/export?' + queryString;
		}
	},
	mounted() {
		this.form.dateFrom = moment().format('YYYY-MM-DD');
		this.form.dateTo = moment().format('YYYY-MM-DD');

		this.loadForm();
	}
}
</script>

<style scoped>
.card-form {
	border-color: #000;
	font-size: 14px;
}

.alert-template {
	display: inline-block;
	vertical-align: middle;
	margin: 0 4px;
	width: 30px;
	height: 15px;
}

.alert-template:first-child {
	margin-left: 0;
}

.btn-collapse {
	color: #212529;
	padding: 0;
	box-shadow: none;
	text-decoration: none;
}

.table-data {
	min-width: 1000px;
	table-layout: fixed;
}

.table-data thead,
.table-data tbody {
	border: 0;
}

.table-data th,
.table-data td {
	width: auto;
	border-top: 1px solid #000;
	border-right: 0;
	border-bottom: 0;
	border-left: 1px solid #000;
	text-align: center;
	vertical-align: middle;
}

.table-data th:nth-child(1),
.table-data td:nth-child(1) {
	width: 12%;
}

.table-data td:nth-child(1) {
	font-size: 12px;
}

.table-data th:nth-child(2),
.table-data td:nth-child(2) {
	width: 27%;
}

.table-data td:nth-child(1),
.table-data td:nth-child(2) {
	text-align: left;
}

.table-data tbody tr:nth-last-child(1) td:nth-child(2) {
	text-align: center;
}

.table-data th {
	text-transform: uppercase;
	font-size: 10px;
}

.table-data td, .table-data td > button {
	font-size: 13px;
}

.table-data th, .table-data td {
	overflow-wrap: break-word;
}

.table-data .bl-bold,
.table-data th:nth-last-child(3),
.table-data td:nth-last-child(3) {
	border-left: 3px solid #000;
}

.table-data thead th {
	border-top: 3px solid #000;
}

.table-data th:nth-last-child(1),
.table-data td:nth-last-child(1) {
	border-right: 3px solid #000;
}

.table-data tbody tr:nth-last-child(1) td {
	border-bottom: 3px solid #000;
}

.table-data thead tr:nth-child(2) th:nth-child(1),
.table-data thead tr:nth-child(2) th:nth-child(2) {
	border-top: 1px solid #000;
}

.table-data tbody tr:nth-last-child(1) th {
	border-bottom: 1px solid #000;
}

.table-data thead tr:nth-child(1) th:nth-child(1),
.table-data thead tr:nth-child(1) th:nth-child(2),
.table-data thead tr:nth-child(1) th:nth-last-child(2),
.table-data thead tr:nth-child(1) th:nth-last-child(1) {
	border-top: 0;
	border-left: 0;
}

.table-data tr:nth-child(1) th:nth-last-child(3) {
	border-top: 0;
}

.table-data tr:nth-child(1) th:nth-last-child(1) {
	border-right: 0;
}

.table-data .date-even td {
	background-color: #DDEBF7;
}

.full {
	background-color: red !important;
	color: #fff;
}

.busy {
	background-color: orange !important;
	color: #fff;
}
</style>
