<template>
  <b-popover
    :target="id"
    placement="rightbottom"
    triggers="hover"
  >
    <div class="row" v-for="area in areas" :key="area.id">
      <div class="col text-nowrap overflow-hidden" :title="area.name">
        {{ area.name }}
      </div>
      <div class="col">
        {{ area.occupation }}/{{ area.quota }}
      </div>
    </div>
  </b-popover>
</template>

<script>
export default {
  name: 'PeriodPopover',
  props: [
    'id',
    'areas'
  ]
}
</script>

<style scoped>
.col:nth-child(1) {
  max-width: calc(100% - 76px);
  flex: 0 0 calc(100% - 76px);
}

.col:nth-child(2) {
  max-width: 76px;
  flex: 0 0 76px;
}

.popover {
  min-width: 260px;
  max-width: 350px;
}
</style>
