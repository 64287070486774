<template>
  <div class="container-fluid calendar">
    <div class="row">
      <div class="col d-flex justify-content-center align-items-center">
        <b-button class="btn-sm" variant="dark" @click="prev()" :disabled="loading">
          <b-icon icon="chevron-compact-left"></b-icon>
        </b-button>
      </div>
      <div class="col">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <div class="row calendar-row">
              <div class="col" v-for="i in 7" :key="i">
                <b-card>
                  <div class="row">
                    <div class="col-9">
                      <b-skeleton></b-skeleton>
                    </div>
                    <div class="col-3 text-right">
                      <b-skeleton-icon icon="pencil-square"></b-skeleton-icon>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-9">
                      <b-skeleton></b-skeleton>
                    </div>
                    <div class="col-3 text-right">
                      <b-skeleton></b-skeleton>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-9">
                      <b-skeleton></b-skeleton>
                    </div>
                    <div class="col-3 text-right">
                      <b-skeleton></b-skeleton>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-9">
                      <b-skeleton></b-skeleton>
                    </div>
                    <div class="col-3 text-right">
                      <b-skeleton></b-skeleton>
                    </div>
                  </div>
                </b-card>
                <b-card v-for="n in 2" :key="n" class="border-0">
                  <div class="row">
                    <div class="col-8">
                      <b-skeleton></b-skeleton>
                    </div>
                    <div class="col-4 text-right">
                      <b-skeleton width="50%" class="d-inline-block mr-1"></b-skeleton>
                      <b-skeleton-icon icon="info-circle-fill"></b-skeleton-icon>
                    </div>
                  </div>
                  <div class="pl-2 mt-2">
                    <div class="row">
                      <div class="col-8">
                        <b-skeleton></b-skeleton>
                      </div>
                      <div class="col-4 text-right">
                        <b-skeleton width="50%" class="d-inline-block mr-1"></b-skeleton>
                        <b-skeleton-icon icon="info-circle-fill"></b-skeleton-icon>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-8">
                        <b-skeleton></b-skeleton>
                      </div>
                      <div class="col-4 text-right">
                        <b-skeleton width="50%" class="d-inline-block mr-1"></b-skeleton>
                        <b-skeleton-icon icon="info-circle-fill"></b-skeleton-icon>
                      </div>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>
          </template>
          <div class="row calendar-row">
            <div class="col" v-for="(date, i) in days" :key="i">
              <day-of-week
                :date="date"
                :selectedPeriods="selectedPeriods"
                :restaurant="restaurant"
                :availabilities="getAvailabilitiesForDate(date)"
                v-on:addToast="addToast($event)"
                v-on:load="load()"
              ></day-of-week>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col d-flex justify-content-center align-items-center">
        <b-button class="btn-sm" variant="dark" @click="next()" :disabled="loading">
          <b-icon icon="chevron-compact-right"></b-icon>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import DayOfWeek from './DayOfWeek';

export default {
  name: 'Calendar',
  components: {
    'day-of-week': DayOfWeek
  },
  props: [
    'date',
    'selectedPeriods',
    'restaurant'
  ],
  data() {
    return {
      loading: true,
      availabilities: {}
    };
  },
  computed: {
    days() {
      if (this.date) {
        return [
          this.date.clone(),
          this.date.clone().add(1, 'd'),
          this.date.clone().add(2, 'd'),
          this.date.clone().add(3, 'd'),
          this.date.clone().add(4, 'd'),
          this.date.clone().add(5, 'd'),
          this.date.clone().add(6, 'd')
        ];
      }

      return [];
    }
  },
  watch: {
    date() {
      this.load();
    },
    restaurant() {
      this.load();
    },
    loading(loading) {
      this.$emit('loading', loading);
    }
  },
  methods: {
    addToast(toast) {
      this.$emit('addToast', toast);
    },
    prev() {
      this.$emit('update', this.date.clone().subtract(1, 'w'));
    },
    next() {
      this.$emit('update', this.date.clone().add(1, 'w'));
    },
    getAvailabilitiesForDate(dateIndex) {
      const key = dateIndex.format('YYYY-MM-DD');

      if (this.availabilities[key]) {
        return this.availabilities[key];
      }

      return null;
    },
    getAvailabilities(restaurantId, date) {
      return this.axios.get(
        '/index.cfm/restaurants/' + restaurantId + '/availabilities/kotaweb',
        {
          params: {
            date: date.format('YYYY-MM-DD')
          },
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      );
    },
    load() {
      this.loading = true;

      if (this.restaurant && this.date) {
        this.getAvailabilities(this.restaurant, this.date).then((response) => {
          this.availabilities = response.data;
          this.loading = false;
        }).catch(() => {
          this.$emit('addToast', {title: 'Erreur', message: 'Impossible de charger les données', variant: 'danger'});
        });
      }
    }
  }
}
</script>

<style scoped>
.calendar {
  padding-right: 5px;
  padding-left: 5px;
  font-size: 13px;
}

.calendar > .row > .col:nth-child(2) {
  max-width: calc(100% - 92px);
  flex: 0 0 calc(100% - 92px);
}

.calendar > .row > .col:nth-child(1),
.calendar > .row > .col:nth-child(3) {
  max-width: 46px;
  flex: 0 0 46px;
}

.calendar .btn {
  height: 70px;
}

.calendar .row {
  margin-right: -5px;
  margin-left: -5px;
}

.calendar .col {
  padding-right: 5px;
  padding-left: 5px;
  white-space: nowrap;
  overflow: hidden;
}

.calendar .calendar-row {
  margin-right: 0px;
  margin-left: 0px;
}

.calendar .calendar-row > .col {
  padding-right: 10px;
  padding-left: 10px;
}

@media (max-width: 1240px) {
  .calendar .row {
    margin-right: -2px;
    margin-left: -2px;
  }

  .calendar .col {
    padding-right: 2px;
    padding-left: 2px;
    flex-basis: auto;
  }

  .calendar .calendar-row {
    margin-right: 0px;
    margin-left: 0px;
  }

  .calendar .calendar-row > .col {
    padding-right: 6px;
    padding-left: 6px;
  }

  .calendar .btn {
    padding-left: 4px;
    padding-right: 4px;
  }

  .calendar > .row > .col:nth-child(2) {
    max-width: calc(100% - 68px);
    flex: 0 0 calc(100% - 68px);
  }

  .calendar > .row > .col:nth-child(1),
  .calendar > .row > .col:nth-child(3) {
    max-width: 34px;
    flex: 0 0 34px;
  }
}

@media (max-width: 990px) {
  .calendar {
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .calendar > .row > .col:nth-child(2) {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .calendar > .row > .col:nth-child(1),
  .calendar > .row > .col:nth-child(3) {
    display: none !important;
  }

  .calendar .row {
    margin-right: -15px;
    margin-left: -15px;
  }

  .calendar .col {
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: auto;
  }

  .calendar .calendar-row {
    margin-right: -15px;
    margin-left: -15px;
  }

  .calendar .calendar-row > .col {
    padding-right: 15px;
    padding-left: 15px;
  }
}
</style>
