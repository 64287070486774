<template>
  <div id="app">
    <router-view v-on:addToast="addToast($event)" />
    <toast v-for="(toast, index) in toasts" :key="index" :title="toast.title" :message="toast.message" :variant="toast.variant"></toast>
  </div>
</template>

<script>
import Toast from './components/Toast';

export default {
  name: 'App',
  components: {
    'toast': Toast
  },
  data() {
    return {
      toasts: [],
      drag: {
        target: null,
        start: {
          x: 0,
          y: 0
        },
        delta: {
          x: 0,
          y: 0
        },
        offset: {
          x: 0,
          y: 0
        }
      }
    }
  },
  methods: {
    addToast(toast) {
      if (toast.variant == undefined) {
        toast.variant = 'default';
      }

      this.toasts.push(toast);
    },
    onGrab(event) {
      this.drag.start.x = event.clientX;
      this.drag.start.y = event.clientY;

      this.drag.target.addEventListener('mousemove', this.onDrag);
      this.drag.target.addEventListener('mouseup', this.onLetGo);
    },
    onDrag(event) {
      event.preventDefault();

      this.drag.delta = {
        x: event.clientX - this.drag.start.x,
        y: event.clientY - this.drag.start.y
      };

      if (this.drag.delta.x === 0 || this.drag.delta.y === 0) {
        return;
      }

      this.drag.target.style.transform = `translate(${this.drag.offset.x + this.drag.delta.x}px, ${this.drag.offset.y + this.drag.delta.y}px)`;
    },
    onLetGo() {
      this.drag.target.removeEventListener('mousemove', this.onDrag);
      this.drag.target.removeEventListener('mouseup', this.onLetGo);

      this.drag.offset.x += this.drag.delta.x;
      this.drag.offset.y += this.drag.delta.y;
      this.drag.delta = {
        x: 0,
        y: 0
      };
    },
    onInitDrag() {
      this.drag = {
        target: null,
        start: {
          x: 0,
          y: 0
        },
        delta: {
          x: 0,
          y: 0
        },
        offset: {
          x: 0,
          y: 0
        }
      };
    }
  },
  mounted() {
    this.$root.$on('bv::modal::shown', (bvEvent) => {
      this.onInitDrag();
      document.querySelector('.modal-header').addEventListener('mousedown', (event) => {
        this.drag.target = bvEvent.target;
        this.onGrab(event);
      });
    })
  }
}
</script>

<style>
#app {
  position: relative;
}

.btn-primary {
    background-color: #2A295C;
    border-color: #2A295C;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    background-color: #2A295C;
    border-color: #2A295C;
}

.btn-primary.focus,
.btn-primary:focus {
    background-color: #2A295C;
    border-color: #2A295C;
}

.btn-primary:hover {
    background-color: #4A497C;
    border-color: #4A497C;
}

.cursor-help {
  cursor: help;
}

.multiselect__placeholder {
  display: none;
}
</style>