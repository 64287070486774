<template>
  <b-row>
    <b-col offset="3" cols="6">
      <b-card>
        <b-form @submit="onSubmit">
          <b-form-group
            label="Nom d'utilisateur"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="form.username"
              type="text"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Mot de passe"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="form.password"
              type="password"
              required
            ></b-form-input>
          </b-form-group>
          <div class="text-center">
              <b-button type="submit" variant="primary">Se connecter</b-button>
          </div>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import router from '../router'

export default {
  name: 'Login',
  props: {
    token: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      this.$emit('addToast', {title: 'Chargement', message: 'Connexion en cours...'});

      this.axios.post(
        '/index.cfm/login',
        {
          username: this.form.username,
          password: this.form.password
        }
      ).then((response) => {
        localStorage.setItem('token', response.data.token);

        this.$emit('addToast', {title: 'Confirmation', message: 'Connexion terminée', variant: 'success'});

        router.push({ name: 'sells' });
      }).catch(() => {
        this.$emit('addToast', {title: 'Erreur', message: 'Impossible de se connecter', variant: 'danger'});
      });
    }
  },
  mounted() {
    if (this.token) {
      localStorage.setItem('token', this.token);
      router.push({ name: 'sells' });
    }
  }
}
</script>

<style scoped>

</style>
