<template>
  <div>
    <b-row class="my-3 mx-3">
      <b-col cols="3">
        <b-form-select v-model="restaurant" :options="restaurantOptions" :disabled="loading"></b-form-select>
      </b-col>
      <b-col cols="3">
        <date-slider
          :date="date"
          :disabled="loading"
          step="7"
          v-on:update="updateDate($event)"
          :format="{'year': 'numeric', 'month': 'long', 'day': 'numeric', 'weekday': undefined}">
        </date-slider>
      </b-col>
      <b-col cols="5">
        <multiselect
          v-model="selectedPeriods"
          :disabled="loading"
          :options="periods"
          track-by="id"
          label="name"
          :multiple="true"
          :close-on-select="false"
          :searchable="false"
          :showLabels="false"
          placeholder=""
        >
          <template slot="selection" slot-scope="{ values }">
            <span class="multiselect__single">
              {{ values.length }} service(s) selectionné(s)
            </span>
          </template>
        </multiselect>
      </b-col>
      <b-col cols="1">
        <b-button variant="dark" @click="refresh()" :disabled="loading">
          <b-spinner small :style="loading ? {} : {'animation': 'none'}"></b-spinner>
          Rafraîchir
        </b-button>
      </b-col>
    </b-row>
    <calendar
      :date="date"
      :selectedPeriods="selectedPeriods"
      :restaurant="restaurant"
      v-on:update="updateDate($event)"
      v-on:addToast="addToast($event)"
      v-on:loading="setLoading($event)"
    ></calendar>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DateSlider from './DateSlider';
import Calendar from './Calendar';
import moment from 'moment';

moment.locale('fr');

export default {
  name: 'Quotas',
  components: {
    'multiselect': Multiselect,
    'date-slider': DateSlider,
    'calendar': Calendar
  },
  data() {
    return {
      loading: true,
      date: null,
      periods: [],
      restaurants: [],
      restaurant: null,
      selectedPeriods: []
    };
  },
  computed: {
    restaurantOptions() {
      let options = [];

      for (let i in this.restaurants) {
        let restaurant = this.restaurants[i];

        options.push({
          value: restaurant.id,
          text: restaurant.name
        });
      }

      return options;
    }
  },
  watch: {
    restaurant(restaurantId) {
      this.getPeriods(restaurantId).then((response) => {
        this.periods = response.data;

        this.selectedPeriods = [];

        response.data.forEach((period) => {
          if (period.has_products) {
            this.selectedPeriods.push(period);
          }
        });
      }).catch(() => {
        this.$emit('addToast', {title: 'Erreur', message: 'Impossible de charger les services', variant: 'danger'});
      });
    }
  },
  methods: {
    addToast(toast) {
      this.$emit('addToast', toast);
    },
    updateDate(val) {
      this.date = val;
    },
    setLoading(val) {
      this.loading = val;
    },
    load() {
      this.getRestaurants().then((response) => {
        this.restaurants = response.data;

        if (this.restaurants.length) {
          this.restaurant = this.restaurants[0].id;
        }
      }).catch(() => {
        this.$emit('addToast', {title: 'Erreur', message: 'Impossible de charger les données', variant: 'danger'});
      });
    },
    getRestaurants() {
      return this.axios.get(
        '/index.cfm/restaurants',
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      );
    },
    getPeriods(restaurantId) {
      return this.axios.get(
        '/index.cfm/restaurants/' + restaurantId + '/periods',
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      );
    },
    refresh() {
      const newDate = this.date.clone();
      this.date = newDate;
    }
  },
  mounted() {
    this.date = moment();
    this.load();
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
</style>
