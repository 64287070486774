<template>
    <div>
        <b-row class="my-3 ml-3">
            <b-col>
                <b-form-select v-model="restaurant" :options="restaurantOptions"></b-form-select>
            </b-col>
            <b-col>
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Rechercher..."
                ></b-form-input>
            </b-col>
            <b-col>
                <b-button @click="add()">
                    Ajouter un nouveau mapping produit
                </b-button>
            </b-col>
        </b-row>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <table class="table b-table table-striped table-hover" style="table-layout: fixed">
                    <thead>
                        <tr>
                            <th v-for="th in 6" :key="th"><b-skeleton></b-skeleton></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="tr in 10" :key="tr">
                            <td v-for="td in 6" :key="td"><b-skeleton></b-skeleton></td>
                            <td>
                                <b-skeleton type="button" class="d-inline-block mr-2" v-for="bt in 2" :key="bt"></b-skeleton>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <b-table striped hover small :items="products" :fields="fields" :filter="filter">
                <template #cell(areas)="row">
                    <span v-for="(area, i) in row.item.areas" :key="area.id"><!---
                    ---><span v-if="i > 0">, </span><!---
                    --->{{ area.name }}<!---
                ---></span>
                </template>

                <template #cell(actions)="row">
                    <b-button class="mr-2" size="sm" @click="edit(row)">
                        Modifier
                    </b-button>
                    <b-button size="sm" @click="remove(row)">
                        Supprimer
                    </b-button>
                </template>
            </b-table>
        </b-skeleton-wrapper>
        <product-modal :modal="modal" :products="products" :metaPrestations="metaPrestations" :extras="extras" :areas="areas" :channels="channels" v-on:reload="reload()" v-on:addToast="addToast($event)"></product-modal>
    </div>
</template>

<script>
import axios from 'axios';
import ProductModal from './ProductModal';
import moment from 'moment';

export default {
    name: 'Products',
    components: {
        'product-modal': ProductModal
    },
    data() {
        return {
            loading: true,
            fields: [
                {
                    label: 'ID',
                    key: 'id',
                    sortable: true
                },
                {
                    label: 'Meta-Prestation ID',
                    key: 'metaprestation_id',
                    sortable: true
                },
                {
                    label: 'Prestation ID',
                    key: 'prestation_id',
                    sortable: true
                },
                {
                    label: 'Extra ID',
                    key: 'extra_id',
                    sortable: true
                },
                {
                    label: 'Libellé produit',
                    key: 'name',
                    sortable: true
                },
                {
                    label: 'Type',
                    key: 'type',
                    sortable: true
                },
                {
                    label: 'Espaces Guestonline associés',
                    key: 'areas',
                    sortable: true
                },
                {
                    label: '',
                    key: 'actions'
                }
            ],
            filter: null,
            products: [],
            metaPrestations: [],
            extras: [],
            areas: [],
            restaurants: [],
            restaurant: null,
            channels: [],
            modal: {
                visible: false,
                title: 'Ajouter un produit',
                button: 'Ajouter',
                form: {
                    type: 'Presta',
                    id: null,
                    metaPrestation: null,
                    extra: null,
                    areas: [],
                    quotaType: 'none',
                    quotas: {},
                    quotaApply: 'day',
                    quotaDate: null,
                    quotaPeriodFrom: null,
                    quotaPeriodTo: null,
                    quotaDayOfWeek: {
                      monday: false,
                      tuesday: false,
                      wednesday: false,
                      thursday: false,
                      friday: false,
                      saturday: false,
                      sunday: false
                    }
                }
            }
        };
    },
    computed: {
        restaurantOptions() {
            let options = [];

            for (let i in this.restaurants) {
                let restaurant = this.restaurants[i];

                options.push({
                    value: restaurant.id,
                    text: restaurant.name
                });
            }

            return options;
        }
    },
    watch: {
      restaurant(restaurantId) {
        this.loading = true;
          
        axios.all([
          this.getProducts(restaurantId),
          this.getMetaPrestations(restaurantId),
          this.getExtras(restaurantId),
          this.getAreas(restaurantId),
          this.getChannels()
        ]).then((responses) => {
          this.products = responses[0].data;
          this.metaPrestations = responses[1].data;
          this.extras = responses[2].data;
          this.areas = responses[3].data;

          for (let i in responses[4].data) {
            this.modal.form.quotas[responses[4].data[i].id] = 0;
          }

          this.channels = responses[4].data;

          this.loading = false;
        }).catch(() => {
          this.addToast({title: 'Erreur', message: 'Impossible de charger les données', variant: 'danger'});
        });
      }
    },
    methods: {
        addToast(toast) {
            this.$emit('addToast', toast);
        },
        load() {
            this.loading = true;

            this.getRestaurants().then((response) => {
                this.restaurants = response.data;
                this.restaurant = this.restaurants[0].id;
            }).catch(() => {
                this.addToast({title: 'Erreur', message: 'Impossible de charger les données', variant: 'danger'});
            });
        },
        reload() {

            this.getProducts(this.restaurant).then((response) => {
                this.products = response.data;
                this.loading = false;
            }).catch(() => {
                this.addToast({title: 'Erreur', message: 'Impossible de charger les produits', variant: 'danger'});
            });
        },
        getProducts(restaurantId) {
            return axios.get(
                '/index.cfm/restaurants/' + restaurantId + '/products',
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }
            );
        },
        getMetaPrestations(restaurantId) {
            return axios.get(
                '/index.cfm/restaurants/' + restaurantId + '/metaprestations',
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }
            );
        },
        getExtras(restaurantId) {
            return axios.get(
                '/index.cfm/restaurants/' + restaurantId + '/extras',
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }
            );
        },
        getAreas(restaurantId) {
            return axios.get(
                '/index.cfm/restaurants/' + restaurantId + '/areas',
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }
            );
        },
        getRestaurants() {
            return axios.get(
                '/index.cfm/restaurants',
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }
            );
        },
        getChannels() {
          return axios.get(
            '/index.cfm/channels',
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              }
            }
          );
        },
        add() {
            this.modal.form.id = null;
            this.modal.form.metaPrestation = null;
            this.modal.form.extra = null;
            this.modal.form.areas = [];

            this.modal.restaurantId = this.restaurant;
            this.modal.visible = true;
            this.modal.title = 'Ajouter un mapping produit';
            this.modal.button = 'Ajouter';
        },
        edit(row) {
            this.modal.form.type = row.item.type;
            this.modal.form.id = row.item.id;

            if (row.item.metaprestation_id) {
                this.modal.form.metaPrestation = row.item.metaprestation_id;
            } else {
                this.modal.form.metaPrestation = null;
            }

            if (row.item.extra_id) {
                this.modal.form.extra = row.item.extra_id;
            } else {
                this.modal.form.extra = null;
            }

            if (row.item.areas) {
                this.modal.form.areas = row.item.areas;
            } else {
                this.modal.form.areas = [];
            }

            this.modal.restaurantId = this.restaurant;
            this.modal.visible = true;
            this.modal.title = 'Modifier un mapping produit';
            this.modal.button = 'Modifier';
        },
        remove(row) {
            let self = this;

            self.addToast({title: 'Chargement', message: 'Suppression d\'un produit...'});

            axios.delete(
                '/index.cfm/products/' + row.item.id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }
            ).then(function () {
                self.reload();
            }).catch(function () {
                self.addToast({title: 'Erreur', message: 'Impossible de supprimer le produit', variant: 'danger'});
            });
        }
    },
    mounted() {
      this.modal.form.quotaDate = moment();
      this.load();
    }
};
</script>

<style scoped>

</style>
