<template>
  <div>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-skeleton type="button" class="my-3"></b-skeleton>
        <b-skeleton-table
          :rows="10"
          :columns="7"
          :table-props="{bordered: true, striped: true, small: true}"
        ></b-skeleton-table>
      </template>

      <b-button variant="primary" class="my-3" v-on:click="openAddUserModal()">
        Ajouter un nouvel utilisateur
      </b-button>
      <table class="table table-bordered table-striped table-sm table-users">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Role</th>
            <th>Canal</th>
            <th>Contact</th>
            <th>Date de désactivation</th>
            <th>Date de dernière connexion</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, i) in users" :key="'user-' + i" :class="{'table-danger': user.disabled_at}">
            <td :title="user.id">
              {{ user.name }}
            </td>
            <td>
              <div v-if="user.role == 'internal'">
                Interne (Résaweb, Vadmin...)
              </div>
              <div v-else-if="user.role == 'external'">
                Externe (Api&You, OTA...)
              </div>
              <div v-else>
                {{ user.role }}
              </div>
            </td>
            <td>
              <div v-if="user.channel" :title="user.channel.id">
                {{ user.channel.name }}
              </div>
            </td>
            <td>
              <div v-if="user.contact" :title="user.contact.id">
                {{ user.contact.name }}
              </div>
            </td>
            <td>
              <div v-if="user.disabled_at">
                {{ dateFormat(user.disabled_at) }}
              </div>
            </td>
            <td>
              <div v-if="user.last_login_at">
                {{ dateFormat(user.last_login_at) }}
              </div>
            </td>
            <td>
              <b-dropdown variant="primary" size="sm" text="Actions">
                <b-dropdown-item v-on:click="openEditUserModal(user)">Modifier</b-dropdown-item>
                <b-dropdown-item v-if="user.disabled_at" v-on:click="enable(user.id)">Activer</b-dropdown-item>
                <b-dropdown-item v-else v-on:click="disable(user.id)">Désactiver</b-dropdown-item>
                <b-dropdown-item v-on:click="resetPassword(user.id)">Générer un nouveau mot de passe</b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </b-skeleton-wrapper>

    <b-modal id="user-modal" :title="modal.title" hide-footer>
      <b-form @submit="saveUser">
        <b-form-group
          label-cols="6"
          content-cols="6"
          label="Nom"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="form.name"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-cols="6"
          content-cols="6"
          label="Rôle"
          label-for="role"
        >
          <b-form-select
            id="role"
            v-model="form.role"
            :options="roleOptions"
          ></b-form-select>
        </b-form-group>

        <b-form-group
          label-cols="6"
          content-cols="6"
          label="Canal"
          label-for="channel"
        >
          <b-form-select
            id="channel"
            v-model="form.channel"
            :options="channelOptions"
          ></b-form-select>
        </b-form-group>

        <b-form-group
          label-cols="6"
          content-cols="6"
          label="Chercher un contact"
          label-for="search"
          description="Retourne 100 contacts au maximum"
        >
          <b-skeleton-wrapper :loading="loadingContacts">
            <template #loading>
              <b-skeleton type="button" width="100%"></b-skeleton>
            </template>
            <b-input-group>
              <b-form-input
                id="search"
                v-model="form.search"
                v-on:keyup.enter="search()"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" v-on:click="search()">
                  <b-icon icon="search"></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-skeleton-wrapper>
        </b-form-group>

        <b-form-group
          label-cols="6"
          content-cols="6"
          label="Contact"
          label-for="contact"
        >
          <b-skeleton-wrapper :loading="loadingContacts">
            <template #loading>
              <b-skeleton type="button" width="100%"></b-skeleton>
            </template>
            <b-form-select
              id="channel"
              v-model="form.contact"
              :options="contactOptions"
            ></b-form-select>
          </b-skeleton-wrapper>
        </b-form-group>

        <div class="text-center">
          <b-button type="submit" variant="primary">
            Sauvegarder
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

moment.locale('fr');

export default {
  name: 'Users',
  data() {
    return {
      loading: true,
      loadingContacts: false,
      users: [],
      channels: [],
      contacts: [],
      modal: {
        title: '',
        userId: null
      },
      form: {
        name: '',
        role: 'external',
        channel: null,
        search: '',
        contact: null
      },
      roleOptions: [
        {
          value: 'internal',
          text: 'Interne (Résaweb, Vadmin...)'
        },
        {
          value: 'external',
          text: 'Externe (Api&You, OTA...)'
        }
      ]
    }
  },
  computed: {
    channelOptions() {
      let options = [{
        value: null,
        text: ''
      }];

      this.channels.forEach((channel) => {
        options.push({
          value: channel.id,
          text: channel.name
        });
      });

      return options;
    },
    contactOptions() {
      let options = [{
        value: null,
        text: ''
      }];

      this.contacts.forEach((contact) => {
        options.push({
          value: contact.id,
          text: contact.name
        });
      });

      return options;
    }
  },
  methods: {
    dateFormat(date) {
      return moment(date).format('dddd D MMMM YYYY à HH[h]mm');
    },
    openAddUserModal() {
      this.modal.title = 'Ajout d\'un nouvel utilisateur';
      this.modal.userId = null;

      this.form.name = '';
      this.form.role = 'external';
      this.form.channel = null;
      this.form.contact = null;

      this.contacts = [];

      this.$bvModal.show('user-modal');
    },
    openEditUserModal(user) {
      this.modal.title = 'Modification d\'un utilisateur';
      this.modal.userId = user.id;

      this.form.name = user.name;
      this.form.role = user.role;
      this.form.channel = user.channel ? user.channel.id : null;
      this.form.contact = user.contact ? user.contact.id : null;

      if (user.contact) {
        this.contacts = [user.contact];
      } else {
        this.contacts = [];
      }
      
      this.$bvModal.show('user-modal');
    },
    saveUser(event) {
      event.preventDefault();

      let params = {
        name: this.form.name,
        role: this.form.role
      };

      if (this.form.channel) {
        params.channel_id = this.form.channel;
      }

      if (this.form.contact) {
        params.contact_id = this.form.contact;
      }

      let request = null;

      if (this.modal.userId) {
        request = this.updateUser(params);
      } else {
        request = this.addUser(params);
      }

      request.then(() => {
        this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
        this.$bvModal.hide('user-modal');
        this.load();
      }).catch(() => {
        this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder les données', variant: 'danger'});
      });
    },
    addUser(params) {
      return this.axios.post(
        '/index.cfm/users',
        params,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      );
    },
    updateUser(params) {
      return this.axios.put(
        '/index.cfm/users/' + this.modal.userId,
        params,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      );
    },
    enable(id) {
      this.axios.put(
        '/index.cfm/users/' + id + '/enable',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      ).then(() => {
        this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
        this.load();
      }).catch(() => {
        this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder les données', variant: 'danger'});
      });
    },
    disable(id) {
      this.axios.put(
        '/index.cfm/users/' + id + '/disable',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      ).then(() => {
        this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
        this.load();
      }).catch(() => {
        this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder les données', variant: 'danger'});
      });
    },
    resetPassword(id) {
      this.axios.put(
        '/index.cfm/users/' + id + '/reset',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      ).then(() => {
        this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
      }).catch(() => {
        this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder les données', variant: 'danger'});
      });
    },
    search() {
      this.loadingContacts = true;

      this.getContacts().then((response) => {
        this.contacts = response.data;
        this.form.contact = null;
        this.loadingContacts = false;
      }).catch(() => {
        this.$emit('addToast', {title: 'Erreur', message: 'Impossible de charger les données', variant: 'danger'});
      });
    },
    getUsers() {
      return this.axios.get(
        '/index.cfm/users',
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );
    },
    getChannels() {
      return this.axios.get(
        '/index.cfm/channels',
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );
    },
    getContacts() {
      return this.axios.get(
        '/index.cfm/users/contacts',
        {
          params: {
            name: this.form.search
          },
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );
    },
    load() {
      this.loading = true;

      axios.all([
        this.getUsers(),
        this.getChannels()
      ]).then((responses) => {
        this.users = responses[0].data;
        this.channels = responses[1].data;

        this.loading = false;
      }).catch(() => {
        this.loading = false;

        this.$emit('addToast', {title: 'Erreur', message: 'Impossible de charger les données', variant: 'danger'});
      });
    },
  },
  mounted() {
    this.load();
  }          
}
</script>

<style scoped>

</style>
