<template>
	<div>
		<h1>Bundles</h1>

		<b-skeleton-wrapper :loading="loading">
			<template #loading>
				<div class="ml-3">
					<div class="d-inline-block align-middle">
						Restaurant
					</div>
					<div class="d-inline-block align-middle ml-3" style="width: 200px;">
						<b-skeleton type="input"></b-skeleton>
					</div>
					<div class="d-inline-block align-middle ml-3">
						<b-skeleton type="button"></b-skeleton>
					</div>
					<b-button class="ml-3" variant="info" @click="loadProducts(form.restaurant)" :disabled="true">
						<b-spinner small></b-spinner>
					</b-button>
				</div>
				<div class="mt-3">
					<b-skeleton-table
						:rows="10"
						:columns="3"
						:table-props="{bordered: true, striped: true, small: true}"
					></b-skeleton-table>
				</div>
			</template>

			<b-form class="mt-3" inline>
				<label for="restaurant">Restaurant</label>
				<b-form-select class="ml-3" id="restaurant" v-model="form.restaurant" :options="restaurantOptions"></b-form-select>
				<b-button class="ml-3" variant="primary" @click="openModal">
					Ajouter un produit à un bundle
				</b-button>
				<b-button class="ml-3" variant="info" @click="loadProducts">
					<b-icon icon="arrow-clockwise"></b-icon>
				</b-button>
			</b-form>

			<table class="table table-bordered table-striped table-sm mt-3">
				<thead>
					<tr>
						<th>Bundle</th>
						<th>Sous-produit</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<template v-for="(bundle, i) in bundles">
						<tr v-for="(child, j) in bundle.children" :key="i + '-' + j">
							<td :title="bundle.id">
								{{ bundle.name }}
							</td>
							<td :title="child.id">
								{{ child.name }}
							</td>
							<td>
								<b-dropdown variant="primary" size="sm" text="Actions">
									<b-dropdown-item v-on:click="prepareDeleteBundle(bundle, child)">Supprimer</b-dropdown-item>
								</b-dropdown>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
		</b-skeleton-wrapper>

		<b-modal id="bundle-modal" title="Ajouter un produit à un bundle" hide-footer>
			<b-form @submit="submit">
				<b-skeleton-wrapper :loading="loading">
					<template #loading>
						<b-form-group label-cols="6" content-cols="6" label="Restaurant">
							<b-skeleton type="input"></b-skeleton>
						</b-form-group>

						<b-form-group label-cols="6" content-cols="6" label="Bundle">
							<b-skeleton type="input"></b-skeleton>
						</b-form-group>
						
						<b-form-group label-cols="6" content-cols="6" label="Sous-produit">
							<b-skeleton type="input"></b-skeleton>
						</b-form-group>

						<div class="text-center">
							<b-skeleton type="button"></b-skeleton>
						</div>
					</template>

					<b-form-group label-cols="6" content-cols="6" label="Restaurant" label-for="restaurant-modal">
						<b-form-select id="restaurant-modal" v-model="form.restaurant" :options="restaurantOptions" :required="true"></b-form-select>
					</b-form-group>

					<b-form-group label-cols="6" content-cols="6" label="Bundle" label-for="bundle">
						<b-form-select id="bundle" v-model="form.bundle" :options="bundleOptions" :required="true"></b-form-select>
					</b-form-group>

					<b-form-group label-cols="6" content-cols="6" label="Sous-produit" label-for="child">
						<b-form-select id="child" v-model="form.child" :options="childOptions" :required="true"></b-form-select>
					</b-form-group>

					<div class="text-center">
						<b-button type="submit" variant="primary">
							Ajouter
						</b-button>
					</div>
				</b-skeleton-wrapper>
			</b-form>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'Bundles',
	data() {
		return {
			loading: true,
			products: [],
			restaurants: [],
			form: {
				restaurant: null,
				bundle: null,
				child: null
			}
		}
	},
	computed: {
		bundles() {
			const bundles = [];

			this.products.forEach((product) => {
				if (product.type == "Presta" && product.children.length) {
					bundles.push(product);
				}
			});

			return bundles;
		},
		restaurantOptions() {
			const options = [];

			this.restaurants.forEach((restaurant) => {
				options.push({
					value: restaurant.id,
					text: restaurant.name
				});
			});

			return options;
		},
		bundleOptions() {
			const options = [];

			this.products.forEach((product) => {
				if (product.type == "Presta" && !product.bundles.length && product.id != this.form.child && !this.bundleExists(product, this.form.child)) {
					options.push({
						value: product.id,
						text: product.name
					});
				}
			});

			return options;
		},
		childOptions() {
			const options = [];

			this.products.forEach((product) => {
				if (product.type == "Presta" && !product.children.length && product.id != this.form.bundle && !this.bundleExists(this.form.bundle, product)) {
					options.push({
						value: product.id,
						text: product.name
					});
				}
			});

			return options;
		}
	},
	watch: {
		products() {
			if (this.bundleOptions.length) {
				this.form.bundle = this.bundleOptions[0].value;
			} else {
				this.form.bundle = null;
			}

			if (this.childOptions.length) {
				this.form.child = this.childOptions[0].value;
			} else {
				this.form.child = null;
			}
		},
		'form.restaurant': function() {
			this.loadProducts();
		}
	},
	methods: {
		bundleExists(bundleToCompare, childToCompare) {
			if (!bundleToCompare || !childToCompare) {
				return false;
			}

			let exists = false;

			this.bundles.forEach((child) => {
				child.bundles.forEach((bundle) => {
					if (bundle.id == bundleToCompare.id && child.id == childToCompare.id) {
						exists = true;

						return false;
					}
				});

				if (exists) {
					return false;
				}
			});

			return exists;
		},
		openModal() {
			if (this.bundleOptions.length) {
				this.form.bundle = this.bundleOptions[0].value;
			} else {
				this.form.bundle = null;
			}

			if (this.childOptions.length) {
				this.form.child = this.childOptions[0].value;
			} else {
				this.form.child = null;
			}

			this.$bvModal.show('bundle-modal');
		},
		prepareDeleteBundle(bundle, child) {
			this.loading = true;

			this.$emit('addToast', {title: 'Initialisation', message: 'Suppression du bundle en cours', variant: 'info'});

			this.deleteBundle({
				bundleId: bundle.id,
				childId: child.id
			}).then(() => {
				this.$emit('addToast', {title: 'Succès', message: 'Suppression du bundle terminée', variant: 'success'});

				this.loadProducts();
			}).catch(() => {
				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de supprimer le bundle', variant: 'danger'});

				this.loading = false;
			});
		},
		submit() {
			this.loading = true;

			this.$emit('addToast', {title: 'Initialisation', message: 'Sauvegarde du bundle en cours', variant: 'info'});

			this.addBundle({
				bundle_product_id: this.form.bundle,
				child_product_id: this.form.child
			}).then(() => {
				this.$emit('addToast', {title: 'Succès', message: 'Sauvegarde du bundle terminée', variant: 'success'});

				this.$bvModal.hide('bundle-modal');

				this.loadProducts();
			}).catch(() => {
				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder le bundle', variant: 'danger'});

				this.loading = false;
			});
		},
		loadProducts() {
			this.loading = true;

			this.$emit('addToast', {title: 'Initialisation', message: 'Chargement des produits en cours', variant: 'info'});

			this.getProducts().then((response) => {
				this.$emit('addToast', {title: 'Succès', message: 'Chargement des produits terminé', variant: 'success'});

				this.products = response.data;

				this.loading = false;
			}).catch(() => {
				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de charger les produits', variant: 'danger'});

				this.loading = false;
			});
		},
		addBundle(params) {
			return this.axios.post(
				'/index.cfm/products/bundles',
				params,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					}
				}
			);
		},
		deleteBundle(params) {
			return this.axios.delete(
				'/index.cfm/products/bundles?bundle_product_id=' + params.bundleId + '&child_product_id=' + params.childId,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					}
				}
			);
		},
		getProducts() {
			return this.axios.get(
				'/index.cfm/restaurants/' + this.form.restaurant + '/products',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				}
			);
		},
		getRestaurants() {
			return this.axios.get(
				'/index.cfm/restaurants',
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				}
			);
		}
	},
	mounted() {
		this.loading = true;

		this.$emit('addToast', {title: 'Initialisation', message: 'Chargement des restaurants en cours', variant: 'info'});

		this.getRestaurants().then((response) => {
			this.$emit('addToast', {title: 'Succès', message: 'Chargement des restaurants terminé', variant: 'success'});

			this.restaurants = response.data;

			if (this.restaurants.length) this.form.restaurant = this.restaurants[0].id;

			this.loading = false;
		}).catch(() => {
			this.$emit('addToast', {title: 'Erreur', message: 'Impossible de charger les restaurants', variant: 'danger'});

			this.loading = false;
		});
	}					
}
</script>

<style scoped>

</style>
