<template>
	<div>
		<b-modal scrollable :id="id" :title="date.format('dddd D MMMM YYYY')" size="lg" hide-footer>
		<div class="row mt-3">
			<div class="col">
				<div>
					<b-form-select v-model="form.period" :options="periodOptions" v-on:change="changePeriod()"></b-form-select>
				</div>
				<div class="mt-3 text-center" v-if="period && period.guestonline">
					<b-form-checkbox v-model="form.periodIsOpened" switch v-on:change="changePeriodStatus()">
						<span v-if="form.periodIsOpened">Service <span class="font-weight-bold">{{ periodName }}</span> ouvert</span>
						<span v-else>Service <span class="font-weight-bold">{{ periodName }}</span> fermé</span>
					</b-form-checkbox>
				</div>
				<table class="table table-sm table-products">
					<thead>
						<tr>
							<th></th>
							<th v-for="channel in channels" :key="channel.id">
								{{ channel.name }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr class="period-quota">
							<td><span class="font-weight-bold">{{ periodName }}</span></td>
							<td v-for="channel in channels" :key="channel.id">
								<div class="period-quota-items">
									<div class="period-quota-value">
										<b-form-input v-model="form.periodQuotas[channel.id]"></b-form-input>
									</div>
									<div class="btn-update-products-by-period">
										<b-button
											type="button"
											variant="primary"
											v-b-tooltip.hover :title="'Remplacer ' + channel.quota + ' par la nouvelle valeur'"
											v-on:click="updateListOfProductsByPeriod(channel.id, form.periodQuotas[channel.id])">
											<b-icon-arrow-down style="width: 10px; height: 10px;"></b-icon-arrow-down>
										</b-button>
									</div>
								</div>
							</td>
						</tr>
						<tr
							v-for="product in products"
							:key="product.id"
						>
							<td :title="product.name">
								<span class="cursor-help">{{ product.name }}</span>
							</td>
							<td
								v-for="channel in channels"
								:key="channel.id"
							>
								<b-form-input v-model="form.productQuotas[channel.id][product.id]" :readonly="product.is_bundle" :title="(product.is_bundle ? 'Les quotas pour les produit bundle ne sont pas modifiable, ils sont calculés à partir de leurs sous-produits' : '')"></b-form-input>
							</td>
						</tr>
					</tbody>
				</table>
				<div>
					<p>Après validation, toutes les valeurs existantes seront écrasées pour le service et les produits affichés.</p>
				</div>
				<div>
					<h5>Commentaires</h5>
					<div>
						<b-form-textarea
							v-model="form.comments"
							rows="3"
							max-rows="8">
						</b-form-textarea>
					</div>
				</div>
				<div class="mt-3 text-center">
					<b-button type="button" variant="primary" v-on:click="save()">VALIDER</b-button>
				</div>
			</div>
		</div>
		</b-modal>
	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

moment.locale('fr');

export default {
	name: 'PeriodModal',
	props: [
		'id',
		'date',
		'initialPeriod',
		'periods'
	],
	data() {
		return {
			form: {
				period: null,
				periodIsOpened: true,
				periodQuotas: {},
				productQuotas: {},
				comments: ''
			},
			error: {
				none: 0,
				quotaEmpty: 1,
				quotaIsNaN: 2,
				badDatas: 3
			}
		};
	},
	computed: {
		period() {
			for (let i in this.periods) {
				if (this.periods[i].id == this.form.period) {
					return this.periods[i];
				}
			}

			return null;
		},
		channels() {
			if (this.period) {
				return this.period.channels;
			}

			return {};
		},
		products() {
			if (this.period) {
				for (let i in this.channels) {
					return this.channels[i].products;
				}
			}

			return {};
		},
		periodOptions() {
			if (this.periods) {
				return Object.values(this.periods)
					.sort((p1, p2) => {
						const sortAscDate = new Date(p1.time_from) - new Date(p2.time_from);
						const sortDescId = p2.id - p1.id;
						return sortAscDate || sortDescId;
					})
					.map(p => {
						return {
							value: p.id,
							text: p.name
						};
					});
			}
			return [];
		},
		periodName() {
			if (this.period && this.period.name) {
				return this.period.name;
			}

			return "";
		}
	},
	watch: {
		periods(periods) {
			for (let period in periods) {
				this.form.period = period.id;
				break;
			}
		},
		'form.period': function() {
			this.load();
		}
	},
	methods: {
		changePeriod() {
			if (this.period && this.period.guestonline) {
				this.form.periodIsOpened = !this.period.guestonline.closed;
			}
		},
		changePeriodStatus() {
			if (this.form.periodIsOpened) {
				this.$emit('addToast', {title: 'Chargement', message: 'Ouverture du service...'});

				this.axios.put(
					'/index.cfm/periods/' + this.period.id + '/open',
					{
						date: this.date.format('YYYY-MM-DD')
					},
					{
						headers: {
							Authorization: 'Bearer ' + localStorage.getItem('token'),
						}
					}
				).then(() => {
					this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
					this.$emit('reload');
				}).catch(() => {
					this.$emit('addToast', {title: 'Erreur', message: 'Impossible d\'ouvrir le service', variant: 'danger'});

					this.form.periodIsOpened = false;
				});
			} else {
				this.$emit('addToast', {title: 'Chargement', message: 'Fermeture du service...'});

				this.axios.put(
					'/index.cfm/periods/' + this.period.id + '/close',
					{
						date: this.date.format('YYYY-MM-DD')
					},
					{
						headers: {
							Authorization: 'Bearer ' + localStorage.getItem('token'),
						}
					}
				).then(() => {
					this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
					this.$emit('reload');
				}).catch(() => {
					this.$emit('addToast', {title: 'Erreur', message: 'Impossible de fermer le service', variant: 'danger'});

					this.form.periodIsOpened = true;
				});
			}
		},
		load() {
			for (let i in this.channels) {
				let channel = this.channels[i];

				this.form.periodQuotas[channel.id] = channel.quota;
				this.form.productQuotas[channel.id] = {};

				for (let j in channel.products) {
					let product = channel.products[j];

					this.form.productQuotas[channel.id][product.id] = product.rawQuota;
				}
			}

			this.form.comments = this.period.comments;
		},
		savePeriodQuotas() {
			let periodQuotas = [];

			let quota = "";
			for (let channelId in this.form.periodQuotas) {
				quota = this.form.periodQuotas[channelId];
				periodQuotas.push({
						"date": this.date.format("YYYY-MM-DD"),
						"period_id": Number(this.form.period),
						"channel_id": Number(channelId),
						"quota": Number(quota)
					});
			}

			return this.axios.put(
				'/index.cfm/periods/quotas',
				periodQuotas,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					}
				}
			);
		},
		saveProductQuotas() {
			let productQuotas = [];

			let quota = "";
			for (const channelId in this.form.productQuotas) {
				for (const productId in this.form.productQuotas[channelId]) {
					quota = this.form.productQuotas[channelId][productId];

					productQuotas.push({
						"date": this.date.format("YYYY-MM-DD"),
						"period_id": Number(this.form.period),
						"channel_id": Number(channelId),
						"product_id": Number(productId),
						"quota": Number(quota)
					});
				}
			}

			return this.axios.put(
				'/index.cfm/products/quotas',
				productQuotas,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					}
				}
			);
		},
		saveComments() {
			const periodId = Number(this.period.id);
			const dateDay = this.date.format("YYYY-MM-DD");
			const comments = this.form.comments.trim();

			return this.axios.put(
				'/index.cfm/periods/' + periodId + '/comments',
				{
				"date": dateDay,
				"comments": comments
			},
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					}
				}
			);
		},
		save() {
			const isValidPeriod = this.isValidPeriodQuota();
			const isvalidProduct = this.isValidProductQuota();
			if (isValidPeriod === this.error.badDatas || isvalidProduct === this.error.badDatas) {
				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder', variant: 'danger'});
				return;
			}

			if (isValidPeriod === this.error.quotaEmpty || isvalidProduct === this.error.quotaEmpty) {
				this.$emit('addToast', {title: 'Erreur', message: 'Veuillez remplir tous les champs vides', variant: 'danger'});
				return;
			}

			if (isValidPeriod === this.error.quotaIsNaN || isvalidProduct === this.error.quotaIsNaN) {
				this.$emit('addToast', {title: 'Erreur', message: 'Veuillez remplir tous les champs avec des nombres ou des chiffres', variant: 'danger'});
				return;
			}

			this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde en cours...'});

			axios.all([
				this.savePeriodQuotas(),
				this.saveProductQuotas(),
				this.saveComments()
			]).then((responses) => {
				if (responses[0].data.message == 'success' && responses[1].data.message == 'success' && responses[2].data.message == 'success') {
					this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
				} else {
					this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder', variant: 'danger'});
				}

				this.$emit('reload');
			}).catch(() => {
				this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder', variant: 'danger'});

				this.$emit('reload');
			});
		},
		isValidNumber(value) {
			return value !== "" && !isNaN(Number(value));
		},
		isValidDate(value) {
			return moment.isDate(new Date(value));
		},
		isValidProductQuota() {
			let quota = ""
			for (const channelId in this.form.productQuotas) {
				for (const productId in this.form.productQuotas[channelId]) {
					quota = this.form.productQuotas[channelId][productId];
					if (quota === "" ) {
						return this.error.quotaEmpty;
					}

					if (!this.isValidNumber(quota)) {
						return this.error.quotaIsNaN;
					}

					if (!this.isValidNumber(this.form.period) || Number(this.form.period) === 0 || !this.isValidNumber(channelId) || Number(channelId) === 0) {
						return this.error.badDatas;
					}
				}
			}

			return this.error.none;
		},
		isValidPeriodQuota() {
			let quota = "";
			for (let channelId in this.form.periodQuotas) {
				quota = this.form.periodQuotas[channelId];
				if (quota === "" ) {
					return this.error.quotaEmpty;
				}

				if (!this.isValidNumber(quota)) {
					return this.error.quotaIsNaN;
				}

				if (!this.isValidNumber(this.form.period) || Number(this.form.period) === 0 || !this.isValidNumber(channelId) || Number(channelId) === 0) {
					return this.error.badDatas;
				}
			}

			return this.error.none;
		},
		updateListOfProductsByPeriod(channelId, periodQuota) {
			if (!this.isValidNumber(channelId) || !this.isValidNumber(periodQuota)) {
				return;
			}

			let channel = this.channels[channelId];

			for (let j in channel.products) {
				let product = channel.products[j];
				if (channel.quota === product.quota) {
					this.form.productQuotas[channel.id][product.id] = Number(periodQuota);
				}
			}

			this.$forceUpdate();
		}
	},
	mounted() {
		this.form.period = this.initialPeriod.id;

		if (this.initialPeriod.guestonline) {
			this.form.periodIsOpened = !this.initialPeriod.guestonline.closed;
		}

		this.load();
	}
}
</script>

<style scoped>
.modal-title {
		text-align: center;
		text-transform: capitalize;
}

.period-quota {
	background-color: #eeeeee;
}

.period-quota-items {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.btn-update-products-by-period,
.btn-update-products-by-period button {
	display: flex;
	align-items: center;
}

.btn-update-products-by-period {
	margin-left: 2px;
}

.btn-update-products-by-period button {
	padding: 5px;
	width: 20px;
	height: 20px;
	background-color: #4947a0;
	border: #4947a0;
}
</style>
