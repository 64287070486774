<template>
  <div>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-skeleton type="button" class="my-3"></b-skeleton>
        <b-skeleton-table
          :rows="10"
          :columns="3"
          :table-props="{bordered: true, striped: true, small: true}"
        ></b-skeleton-table>
      </template>

      <b-button variant="primary" class="my-3" v-on:click="openAddChannelModal()">
        Ajouter un nouveau canal
      </b-button>
      <table class="table table-bordered table-striped table-sm table-channels">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Date de désactivation</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(channel, i) in channels" :key="'channel-' + i" :class="{'table-danger': channel.disabled_at}">
            <td :title="channel.id">
              {{ channel.name }}
            </td>
            <td>
              <div v-if="channel.disabled_at">
                {{ dateFormat(channel.disabled_at) }}
              </div>
            </td>
            <td>
              <b-dropdown variant="primary" size="sm" text="Actions">
                <b-dropdown-item v-on:click="openEditChannelModal(channel)">Modifier</b-dropdown-item>
                <b-dropdown-item v-if="channel.disabled_at" v-on:click="enable(channel.id)">Activer</b-dropdown-item>
                <b-dropdown-item v-else v-on:click="disable(channel.id)">Désactiver</b-dropdown-item>
                <b-dropdown-item v-on:click="openUsersModal(channel)">Voir la liste des utilisateurs associés</b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </b-skeleton-wrapper>

    <b-modal id="channel-modal" :title="modal.title" hide-footer>
      <b-form @submit="saveChannel">
        <b-form-group
          label-cols="6"
          content-cols="6"
          label="Nom"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="form.name"
            required
          ></b-form-input>
        </b-form-group>

        <div class="h5">Guestonline tokens:</div>

        <b-form-group
          label-cols="6"
          content-cols="6"
          v-for="(env, i) in envs"
          :key="'env-' + i"
          :label="env.name"
          :label-for="'env-' + i"
        >
          <b-form-input
            :id="'env-' + i"
            v-model="form.envs[env.id]"
            required
          ></b-form-input>
        </b-form-group>

        <div class="text-center">
          <b-button type="submit" variant="primary">
            Sauvegarder
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <b-modal id="users-modal" title="Utilisateurs" hide-footer>
      <table class="table table-bordered table-sm table-users">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Contact</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, i) in modal.users" :key="'user-' + i">
            <td :title="user.id">
              {{ user.name }}
            </td>
            <td>
              <div v-if="user.contact" :title="user.contact.id">
                {{ user.contact.name }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

moment.locale('fr');

export default {
  name: 'Channels',
  data() {
    return {
      loading: true,
      channels: [],
      envs: [],
      modal: {
        title: '',
        channelId: null,
        users: []
      },
      form: {
        name: '',
        envs: {}
      }
    }
  },
  methods: {
    dateFormat(date) {
      return moment(date).format('dddd D MMMM YYYY à HH[h]mm');
    },
    openAddChannelModal() {
      this.modal.title = 'Ajout d\'un nouveau canal';
      this.modal.channelId = null;

      this.form.name = '';

      for (let i in this.envs) {
        this.form.envs[this.envs[i].id] = '';
      }

      this.$bvModal.show('channel-modal');
    },
    openEditChannelModal(channel) {
      this.modal.title = 'Modification d\'un canal';
      this.modal.channelId = channel.id;

      this.form.name = channel.name;

      for (let i in this.envs) {
        this.form.envs[this.envs[i].id] = '';

        for (let j in channel.envs) {
          if (channel.envs[j].env_id == this.envs[i].id) {
            this.form.envs[this.envs[i].id] = channel.envs[j].token;
            break;
          }
        }
      }

      this.$bvModal.show('channel-modal');
    },
    openUsersModal(channel) {
      this.modal.users = channel.users;
      
      this.$bvModal.show('users-modal');
    },
    saveChannel(event) {
      event.preventDefault();

      let params = {
        name: this.form.name,
        envs: []
      };

      for (let env_id in this.form.envs) {
        params.envs.push({
          env_id: env_id,
          token: this.form.envs[env_id]
        });
      }

      let request = null;

      if (this.modal.userId) {
        request = this.updateChannel(params);
      } else {
        request = this.addChannel(params);
      }

      request.then(() => {
        this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
        this.$bvModal.hide('channel-modal');
        this.load();
      }).catch(() => {
        this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder les données', variant: 'danger'});
      });
    },
    addChannel(params) {
      return this.axios.post(
        '/index.cfm/channels',
        params,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      );
    },
    updateChannel(params) {
      return this.axios.put(
        '/index.cfm/channels/' + this.modal.channelId,
        params,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      );
    },
    enable(id) {
      this.axios.put(
        '/index.cfm/channels/' + id + '/enable',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      ).then(() => {
        this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
        this.load();
      }).catch(() => {
        this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder les données', variant: 'danger'});
      });
    },
    disable(id) {
      this.axios.put(
        '/index.cfm/channels/' + id + '/disable',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      ).then(() => {
        this.$emit('addToast', {title: 'Sauvegarde', message: 'Sauvegarde terminée', variant: 'success'});
        this.load();
      }).catch(() => {
        this.$emit('addToast', {title: 'Erreur', message: 'Impossible de sauvegarder les données', variant: 'danger'});
      });
    },
    getChannels() {
      return this.axios.get(
        '/index.cfm/channels',
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );
    },
    getEnvs() {
      return this.axios.get(
        '/index.cfm/channels/envs',
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );
    },
    load() {
      this.loading = true;

      axios.all([
        this.getChannels(),
        this.getEnvs()
      ]).then((responses) => {
        this.channels = responses[0].data;

        let envs = responses[1].data;

        for (let i in envs) {
          this.form.envs[envs[i].id] = '';
        }

        this.envs = envs;

        this.loading = false;
      }).catch(() => {
        this.loading = false;

        this.$emit('addToast', {title: 'Erreur', message: 'Impossible de charger les données', variant: 'danger'});
      });
    },
  },
  mounted() {
    this.load();
  }          
}
</script>

<style scoped>

</style>
