<template>
  <div class="login-layout pt-5">
    <b-container>
      <h1 class="text-center mb-5"><img src="/img/logo.png" alt="Kotaweb"></h1>
      <router-view v-on:addToast="addToast($event)" />
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
  methods: {
    addToast(toast) {
      this.$emit('addToast', toast);
    }
  }
}
</script>

<style>
.login-layout {
  height: 100vh;
  background-color: #2A295C;
}
</style>
